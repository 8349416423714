import { createContext, useContext, useMemo } from 'react';
import { useQueryParams } from '../hooks/misc';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useWorkspace } from './WorkspaceProvider';
import { useParams } from 'react-router-dom';

const templateQuery = loader('./TemplateProvider.graphql');

const TemplateProviderContext = createContext();
export const TemplateProvider = ({ children }) => {
  const { getParam } = useQueryParams();
  const type = getParam('type');
  const selectedWorkspace = getParam('workspace');
  const { workspaceId } = useWorkspace();
  const { id } = useParams();

  const { data: { inspectionTemplates = [] } = {} } = useQuery(templateQuery, {
    skip: !workspaceId,
    variables: {
      workspaceId: `${selectedWorkspace || workspaceId}`,
    },
  });
  const templates = useMemo(() => {
    if (!!type) {
      return inspectionTemplates
        ?.filter((t) => t.templateType === type)
        .filter((a) => !a.isArchived);
    }
    if (!!id) {
      return inspectionTemplates;
    }
    return inspectionTemplates;
  }, [id, inspectionTemplates, type]);

  return (
    <TemplateProviderContext.Provider value={{ templates }}>
      {children}
    </TemplateProviderContext.Provider>
  );
};
export const useTemplates = () => useContext(TemplateProviderContext);
