import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import styles from './InspectionItemText.module.scss';
import { Text } from '../../typography';
import { Icon } from '@mui/material';
import { loader } from 'graphql.macro';
import { Table } from 'react-bootstrap';
import SimpleUserCard from '../../SimpleUserCard';
import { calculateRisk, colorLookup } from '../../../utilities/riskMatrix';
import InspectionItemInput from './InspectionItemInput';
import {
  statusType,
  inputTypes,
  getExtension,
} from '../../../utilities/inspection';
import findFileIcon from '../../../utilities/files';
import { useInspection } from '../../../hooks/offline-hooks/createInspectionhook';
import ImageWrapper from '../../offline-wrapper-functions/image-wrapper';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../../utilities/translator/translation_keys.js';
import { getLocalTime } from '../../../utilities/time.js';
import { downloadFile } from '../../../utilities/inspection';
import AudioWrapper from '../../offline-wrapper-functions/audio-wrapper.js';
import { numberCheck } from '../../../utilities/inspection';
import { useModal } from '../../../providers/ModalProvider.jsx';
import { openFailureModal } from '../../../graphql/cache/modal.js';
import { customColors } from '../../../utilities/misc.js';
import InspectionItemComments from './InspectionItemComments.jsx';
import { useOnlineStatus } from '../../../hooks/offline-hooks/offline-misc.js';

const addActionItemMutation = loader('./InspectionItemText.actionItem.graphql');

const getStatusIcon = (item) => {
  switch (item.status) {
    case statusType.pass:
      return 'check_circle';
    case statusType.notApplicable:
      return 'check_box';
    default:
      return 'cancel';
  }
};

export default function InspectionItemText({
  title,
  description,
  inputType,
  questionId,
  inspectionId,
  inspectionTitle,
  inspectionType,
  vehicleId,
  vehicleUnitNumber,
  additionalData,
  item,
  required,
  isDraft,
  workspaceId,
  internalType,
  refetch,
  isBundle,
}) {
  const user = item?.user;
  const [isEditing, setIsEditing] = useState(!item?.value);
  const { addInspectionTestResults, updateInspectionTestResults } =
    useInspection();
  const [addActionItem] = useMutation(addActionItemMutation);
  const [isRecording, setIsRecording] = useState(false);
  const [expandTranscript, setExpandTranscript] = useState(false);
  const [editingComponent, setEditingComponent] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [editValue, setEditValue] = useState(item?.value || '');
  const { openConfirmationModal } = useModal();
  const isOnline = useOnlineStatus();
  const [fullScreen, setFullScreen] = useState(false);

  const { t } = useTranslation();
  const audioRecordingName = `${t(
    keys.assessments.VOICE_RECORDING,
  )} ${getLocalTime(item?.dateModified).format('YYYY-MM-DD')}`;

  const textDisplay = [
    inputTypes.time,
    inputTypes.dateTime,
    inputTypes.text,
    inputTypes.multiText,
    inputTypes.number,
  ];

  const editableInputs = [
    ...textDisplay,
    inputTypes.date,
    inputTypes.image,
    inputTypes.signature,
    inputTypes.file,
    inputTypes.test,
    inputTypes.user,
    inputTypes.audio,
  ];

  const nonEditableInputs = [
    inputTypes.multiSignature,
    inputTypes.multiFile,
    inputTypes.multiImage,
    inputTypes.multiUser,
    inputTypes.matrix,
    inputTypes.multiSelect,
    inputTypes.select,
    inputTypes.table,
  ];

  useEffect(() => {
    if (!item?.value) {
      setIsEditing(true);
      setEditValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const onSubmit = (params) => {
    const {
      value,
      failed = item?.failed ?? false,
      status = item?.status ?? statusType.pass,
      failNotes,
      failImage,
      userId,
      additional,
      impact,
      probability,
    } = params;
    if (item?.id) {
      updateInspectionTestResults(
        inspectionId,
        item.id,
        value,
        failed,
        status,
        failNotes,
        failImage,
        userId || null,
        additional,
        impact,
        probability,
        questionId,
        inputType,
      );
      setIsEditing(false);
    } else {
      addInspectionTestResults(
        inspectionId,
        questionId,
        value,
        failed,
        status,
        failNotes,
        failImage,
        userId || null,
        additional,
        impact,
        probability,
        inputType,
      );
      setIsEditing(false);
    }
    if (failNotes) {
      const actionItemTitle =
        inspectionType === 'VEHICLE'
          ? t(keys.assessments.VEHICLE_FAIL, {
              title: title,
              unit: vehicleUnitNumber,
            })
          : t(keys.assessments.REPORT_FAIL, {
              question: title,
              template: inspectionTitle,
            });

      const actionItemDescription = `${failNotes}`;
      if (userId) {
        addActionItem({
          variables: {
            title: actionItemTitle,
            description: actionItemDescription,
            assigneeId: userId,
            type: inspectionType,
            inspectionId: inspectionId,
            vehicleId: vehicleId,
            workspaceId: workspaceId,
          },
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.header}>
          <div className={styles.subHeader}>
            <Text weight="bold" noMargin>
              {`${
                !!internalType
                  ? t(getTranslationKey(title, 'assessments'))
                  : title
              }`}{' '}
              <span style={{ color: 'red', fontWeight: 'bold' }}>
                {' '}
                {`${required ? '*' : ''}`}
              </span>
            </Text>
            {!!item?.value && !isEditing && (
              <Icon
                baseClassName="material-icons-outlined"
                className={styles[`${item.status.toLowerCase()}Icon`]}
                sx={{ fontSize: '1.3rem' }}
              >
                {getStatusIcon(item)}
              </Icon>
            )}
          </div>
          <div className={styles.rightIcons}>
            {inputType !== inputTypes.test &&
              inputType !== inputTypes.user &&
              !item?.failed &&
              !!item?.value &&
              isDraft &&
              !isEditing && (
                <Icon
                  sx={{ color: customColors.RED_LIGHT, fontSize: '1.2rem' }}
                  className={styles.icon}
                  onClick={() =>
                    openFailureModal({
                      title,
                      description,
                      onSubmit: (notes, imageUrl, assigneeId) => {
                        onSubmit({
                          value: item?.value,
                          failNotes: notes,
                          failImage: imageUrl,
                          userId: assigneeId,
                          failed: true,
                          status: statusType.fail,
                          additional: item?.additional,
                          impact: item?.impact,
                          probability: item?.probability,
                        });
                      },
                    })
                  }
                >
                  flag
                </Icon>
              )}
            {editableInputs.includes(inputType) && isDraft && !isEditing && (
              <Icon
                baseClassName="material-icons-outlined"
                color="disabled"
                onClick={() => {
                  if (inputType === inputTypes.audio) {
                    openConfirmationModal({
                      title: t(keys.assessments.CLEAR_RECORDING),
                      variant: 'danger',
                      buttonText: t(keys.action.CLEAR),
                      description: t(keys.assessments.CLEAR_RECORDING_WARNING),
                      onSubmit: () => {
                        updateInspectionTestResults(
                          inspectionId,
                          item.id,
                          '',
                          false,
                          'PASS',
                          null,
                          null,
                          null,
                          null,
                        );
                        setIsEditing(!isEditing);
                        setEditValue('');
                      },
                    });
                  } else {
                    setIsEditing(!isEditing);
                    setEditValue(item?.value);
                    setEditingComponent(item?.id);
                  }
                }}
                className={styles.icon}
                sx={{ fontSize: '1.3rem' }}
              >
                create
              </Icon>
            )}
            {!isEditing && isOnline && (
              <Icon
                className={styles.icon}
                color={
                  showComments || item?.comments?.length
                    ? 'primary'
                    : 'disabled'
                }
                baseClassName="material-icons-outlined"
                sx={{ fontSize: '1.3rem' }}
                onClick={() => setShowComments(!showComments)}
              >
                {showComments
                  ? 'speaker_notes_off'
                  : item?.comments?.length
                  ? 'mark_unread_chat_alt'
                  : 'chat'}
              </Icon>
            )}
          </div>
        </div>
        <Text noMargin>{description}</Text>
        {inputType === inputTypes.number && !!additionalData ? (
          <Text
            className={styles.numberText}
            size="sm"
            color="secondary"
            noMargin
          >
            {numberCheck(additionalData)}
          </Text>
        ) : null}
        <div className={styles.spacer} />

        {user && !isEditing && inputType === inputTypes.user && (
          <SimpleUserCard
            size="md"
            weight="semiBold"
            largeAvatar
            className={styles.userCard}
            user={user}
          />
        )}
        {(isEditing || nonEditableInputs.includes(inputType)) && isDraft && (
          <div className={styles.inputQuestions}>
            <InspectionItemInput
              item={item}
              inputType={inputType}
              title={title}
              questionId={questionId}
              inspectionId={inspectionId}
              editingComponent={editingComponent}
              additionalData={
                inputType === inputTypes.multiSignature ||
                inputType === inputTypes.multiFile
                  ? item?.additional
                  : additionalData
              }
              isEditing={isEditing}
              inputValue={editValue}
              setInputValue={setEditValue}
              isDraft={isDraft}
              inputTypes={inputTypes}
              setIsEditing={setIsEditing}
              isRecording={isRecording}
              setIsRecording={setIsRecording}
              isBundle={isBundle}
              onSubmit={(
                value,
                failed = false,
                status = statusType.pass,
                failNotes,
                failImage,
                userId,
                additional,
                impact,
                probability,
              ) => {
                onSubmit(
                  value,
                  failed,
                  status,
                  failNotes,
                  failImage,
                  userId,
                  additional,
                  impact,
                  probability,
                );
              }}
            />
          </div>
        )}
        {!isDraft && !item?.value && (
          <Text className={styles.textResponse} color="accentPrimary" noMargin>
            {t(keys.assessments.NO_RESPONSE)}
          </Text>
        )}
        {inputType === inputTypes.audio && !isEditing && item?.additional && (
          <div>
            <div className={styles.fileDisplay}>
              <div className={styles.audioCard}>
                <img
                  className={styles.fileIcon}
                  alt="audio recording"
                  src={findFileIcon('audio')}
                />
                <Text noMargin weight="semiBold">
                  {audioRecordingName}
                </Text>
                <Icon
                  baseClassName="material-icons-outlined"
                  className={styles.icon}
                  color="primary"
                  onClick={() =>
                    downloadFile(item?.additional, audioRecordingName)
                  }
                >
                  download
                </Icon>
              </div>
              <AudioWrapper audio={item?.additional}>
                {t(keys.assessments.UNSUPPORTED_AUDIO)}
              </AudioWrapper>
            </div>
            <Text
              color="secondary"
              size="sm"
              hover
              className={styles.viewTranscript}
              textAlign="center"
              onClick={() => setExpandTranscript(!expandTranscript)}
            >
              {expandTranscript
                ? t(keys.assessments.HIDE_TRANSCRIPT)
                : t(keys.assessments.VIEW_TRANSCRIPT)}
            </Text>
            <Text
              size="xs"
              weight="semiBold"
              color="secondary"
              textAlign="center"
            >
              Your company has early access to this experimental feature. The
              voice to text tool may have some bugs which will be fixed in the
              near future. You can always contact us at support@opasmobile.com
              if you have any issues.
            </Text>
            <Text
              color="accentPrimary"
              noMargin
              className={expandTranscript ? styles.textOverflow : styles.hide}
            >
              {item?.value || t(keys.common.NONE)}
            </Text>
          </div>
        )}
        {inputType === inputTypes.multiFile && !isDraft && !!item?.value && (
          <div className={styles.multiFileDisplay}>
            {item?.additional?.split('|').map((additional, i) => (
              <div
                key={`$file-${additional}-${i}`}
                className={styles.fileDisplay}
                onClick={(e) => {
                  internalType === 'RECORDED_MEETING'
                    ? e.stopPropagation()
                    : openConfirmationModal({
                        title: t(keys.action.DOWNLOAD_VARIABLE, {
                          variable: additional,
                        }),
                        description: t(keys.action.DOWNLOAD_CONFIRMATION, {
                          variable: additional,
                        }),
                        variant: 'warning',
                        onSubmit: () => {
                          const url = item?.value?.split('|')[i];
                          downloadFile(url, additional);
                        },
                      });
                }}
              >
                <div className={styles.card}>
                  <img
                    className={styles.fileIcon}
                    alt={`${additional} file`}
                    src={findFileIcon(
                      getExtension(
                        item?.value.includes('|')
                          ? item?.value.split('|')[i]
                          : item?.value,
                      ),
                    )}
                  />
                  <Text noMargin weight="semiBold">
                    {additional}
                  </Text>
                </div>
                {internalType === 'RECORDED_MEETING' ? (
                  <div>
                    <AudioWrapper audio={item?.value?.split('|')[i]}>
                      {t(keys.assessments.UNSUPPORTED_AUDIO)}
                    </AudioWrapper>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        )}
        {inputType === inputTypes.file && !isEditing && (
          <div
            className={styles.fileDisplay}
            onClick={() => {
              openConfirmationModal({
                title: t(keys.action.DOWNLOAD_VARIABLE, {
                  variable: item?.additional,
                }),
                description: t(keys.action.DOWNLOAD_CONFIRMATION, {
                  variable: item?.additional,
                }),
                variant: 'warning',
                onSubmit: () => {
                  downloadFile(item?.value, item?.additional);
                },
              });
            }}
          >
            <div className={styles.card}>
              <img
                className={styles.fileIcon}
                alt={`${item?.additional} file`}
                src={findFileIcon(getExtension(item?.value ? item?.value : ''))}
              />
              <Text noMargin weight="semiBold">
                {item?.additional}
              </Text>
            </div>
          </div>
        )}
        {inputType === inputTypes.multiSignature && !isDraft && (
          <div className={styles.multiSignatureDisplay}>
            {item?.value?.split('|').map((url, i) => (
              <div key={`${item?.id} + ${i}`}>
                <Text
                  color="accentPrimary"
                  noMargin
                  className={styles.textResponse}
                >
                  {item?.additional?.split('|')[i]}
                </Text>
                <ImageWrapper
                  key={url}
                  alt="signature2"
                  className={styles.displayImage}
                  image={url}
                />
              </div>
            ))}
          </div>
        )}
        {inputType === inputTypes.multiUser && !isDraft && (
          <div className={styles.usersContainer}>
            {item?.users?.map((user, i) => (
              <div key={`${user.id} + ${i}`}>
                <SimpleUserCard size="md" mediumAvatar user={user} />
              </div>
            ))}
          </div>
        )}
        {inputType === inputTypes.matrix && !isDraft && item?.value && (
          <div className={styles.matrix}>
            <div
              className={styles.matrixLabel}
              style={{
                backgroundColor:
                  colorLookup[
                    calculateRisk(item?.impact * item?.probability).level
                  ],
              }}
            >
              <Text
                color="white"
                noMargin
                noSelect
                weight="semiBold"
                textAlign="center"
              >
                {calculateRisk(item?.impact * item?.probability).level}
              </Text>
            </div>

            <div className={styles.matrixText}>
              <Text
                color="accentPrimary"
                className={styles.textResponse}
                noMargin
              >
                <span
                  style={{
                    color: 'black',
                    fontFamily: 'eina03regular',
                    fontSize: 'small',
                  }}
                >
                  {`${t(keys.common.PROBABILITY)}: `}
                </span>
                {item?.probability}
              </Text>
              <Text
                color="accentPrimary"
                className={styles.textResponse}
                noMargin
              >
                <span
                  style={{
                    color: 'black',
                    fontFamily: 'eina03regular',
                    fontSize: 'small',
                  }}
                >
                  {`${t(keys.common.IMPACT)}: `}
                </span>
                {item?.impact}
              </Text>
            </div>
          </div>
        )}
        {inputType === inputTypes.signature && !isEditing && (
          <div className={styles.sigSingle}>
            <Text
              color="accentPrimary"
              className={styles.textResponse}
              noMargin
            >
              {item?.additional}
            </Text>
            <ImageWrapper
              alt="signature3"
              className={styles.displayImage}
              image={item?.value}
            />
          </div>
        )}
        {inputType === inputTypes.image && !isEditing && (
          <ImageWrapper
            alt="uploaded"
            className={styles.image}
            image={item?.value}
          />
        )}
        {inputType === inputTypes.multiImage &&
          !isDraft &&
          (item?.value ? (
            <div className={styles.multiImage}>
              {item?.value?.split('|').map((item, i) => (
                <ImageWrapper
                  key={`${item?.id} + ${i}`}
                  alt="uploaded"
                  className={styles.image}
                  image={item}
                />
              ))}
            </div>
          ) : null)}
        {(textDisplay.includes(inputType) && !isEditing) ||
        (inputType === inputTypes.select && !isDraft) ||
        (inputType === inputTypes.date && !isEditing) ? (
          <Text noMargin color="accentPrimary" className={styles.textResponse}>
            {item?.value}
          </Text>
        ) : null}

        {inputType === inputTypes.table && !isDraft && item?.value && (
          <div
            className={fullScreen ? styles.fullScreen : styles.tableContainer}
          >
            {fullScreen && (
              <div className={styles.header}>
                <Text size="lg" weight="bold">
                  {title}
                </Text>
                <Icon
                  className={styles.hover}
                  onClick={() => setFullScreen(false)}
                >
                  close
                </Icon>
              </div>
            )}
            <br />
            <div className={styles.tableContainer}>
              <Table striped bordered className={styles.table}>
                <thead>
                  <tr>
                    {additionalData?.split('|').map((col, i) => (
                      <th
                        className={styles.tableHeader}
                        key={`table-${col}-${i}`}
                        style={{ minWidth: '10rem' }}
                      >
                        <Text weight="semiBold" noMargin size="sm">
                          {col}
                        </Text>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {item?.value.split('|').map((data, rowIndex) => (
                    <tr key={`top-cell-${rowIndex}`}>
                      {data.split('\n').map((item, colIndex) => (
                        <td key={`cell-${rowIndex}-${colIndex}`}>
                          <Text noMargin size="sm">
                            {item}
                          </Text>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {!fullScreen && (
              <Text
                hover
                onClick={() => setFullScreen(true)}
                noMargin
                size="sm"
                color="accentPrimary"
                textAlign="center"
              >
                VIEW FULLSCREEN
              </Text>
            )}
          </div>
        )}
        {inputType === inputTypes.multiSelect && !isDraft && (
          <Text color="accentPrimary" className={styles.textResponse} noMargin>
            {item?.value.replace(/\|/g, ', ')}
          </Text>
        )}
        {(inputType === inputTypes.test || !!item?.failed) &&
          (item?.status === 'FAIL'
            ? !!item?.failNotes &&
              !isEditing && (
                <div className={styles.failContainer}>
                  <div className={styles.header}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Text weight="bold" noMargin>
                        {t(keys.assessments.FAIL_REPORT)}
                      </Text>
                      <Icon style={{ color: 'red' }}>flag</Icon>
                    </div>
                    {isDraft && !isEditing && (
                      <Icon
                        style={{ opacity: '0.5', cursor: 'pointer' }}
                        onClick={() => {
                          openConfirmationModal({
                            title: t(keys.action.DELETE_VARIABLE, {
                              variable: t(keys.assessments.FAIL_REPORT),
                            }),
                            description: t(keys.assessments.DELETE_FAIL_REPORT),
                            variant: 'danger',
                            onSubmit: () =>
                              onSubmit({
                                value: item?.value,
                                failNotes: null,
                                failImage: null,
                                failed: false,
                                status: statusType.pass,
                                userId: null,
                                additional: item?.additional,
                                impact: item?.impact,
                                probability: item?.probability,
                              }),
                          });
                        }}
                      >
                        undo
                      </Icon>
                    )}
                  </div>
                  <Text weight="semiBold" color="secondary" size="sm" noMargin>
                    {title}
                  </Text>
                  {item?.user && (
                    <div className={styles.failComponent}>
                      <Text
                        weight="semiBold"
                        color="red"
                        noMargin
                        className={styles.left}
                      >
                        {`${t(keys.common.ACTION_ITEM)}`}
                      </Text>
                      <SimpleUserCard size="md" weight="semiBold" user={user} />
                    </div>
                  )}

                  <div className={styles.failComponent}>
                    <Text
                      weight="semiBold"
                      color="red"
                      noMargin
                      className={styles.left}
                    >
                      {`${t(keys.assessments.REASON_FOR_FAILURE)}`}
                    </Text>
                    <Text size="md" weight="semiBold" noMargin>
                      {item.failNotes || 'None'}
                    </Text>
                  </div>

                  {item?.failImage && (
                    <div className={styles.failComponent}>
                      <Text
                        weight="semiBold"
                        color="red"
                        noMargin
                        className={styles.left}
                      >
                        {`${t(keys.assessments.FAIL_IMAGE)}`}
                      </Text>
                      <ImageWrapper
                        image={item.failImage}
                        className={styles.failImage}
                        alt="failure"
                      />
                    </div>
                  )}
                </div>
              )
            : null)}
        {showComments ? (
          <InspectionItemComments
            itemId={item?.id}
            comments={item?.comments || []}
            refetch={refetch}
          />
        ) : null}
      </div>
    </div>
  );
}
