import { useMemo, useState } from 'react';
import MTTable from '../../components/tables/MTable';
import { useTableComponents } from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { NetworkStatus } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { Text } from '../../components/typography';
import { useQueryParams } from '../../hooks/misc';
import {
  getLabel,
  incidentStatusTypes,
  incidentTypes,
} from '../../utilities/incidents';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents';
import { useURLParams } from '../../providers/URLParamProvider';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { getRoute } from '../../constants/strings';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider';

const incidentsQuery = loader('./IncidentTable.fetch.graphql');

export default function IncidentTable() {
  const {
    textCell,
    userCell,
    userFilterOptions,
    dateCell,
    labelCell,
    workspaceCell,
  } = useTableComponents();
  const navigate = useNavigate();
  const { availableWorkspaces } = useWorkspace();
  const { getParam } = useQueryParams();
  const { page, pageSize, filters, sort } = useURLParams();
  const { t } = useTranslation();

  const [dateSelectors, setDateSelectors] = useState({
    dateOfIncidentMin: getParam('dateOfIncident')?.split('__')[0] || '',
    dateOfIncidentMax: getParam('dateOfIncident')?.split('__')[1] || '',
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
  });

  const { networkStatus, data: { incidents, incidentsCount = 0 } = {} } =
    useQuery(incidentsQuery, {
      skip: !page || !pageSize || !sort,
      variables: {
        options: {
          page,
          pageSize,
          filters,
          sort,
        },
      },
    });

  const workspaces = availableWorkspaces?.map((w) => ({
    label: `${w.title}`,
    value: `${w.id}`,
  }));

  const columns = useMemo(
    () => [
      {
        accessorKey: 'templateType',
        header: undefined,
        enableSorting: false,
        size: 50,
        enableColumnFilter: false,
        Cell: () => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: 'red',
            }}
          >
            fmd_bad
          </Icon>
        ),
      },
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'type',
        header: t(keys.common.TYPE),
        filterVariant: 'select',
        filterSelectOptions: Object.keys(incidentTypes),
        Cell: ({ cell }) => (
          <Text size="sm" noMargin weight="semiBold" noSelect>
            {t(getTranslationKey(cell.getValue(), 'incidents')) ||
              cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'subtype',
        header: t(keys.common.SUBTYPE),
        filterVariant: 'select',
        filterSelectOptions: Object.values(incidentTypes).flat(),
        Cell: ({ cell }) => (
          <Text size="sm" noMargin weight="semiBold" noSelect>
            {t(getTranslationKey(cell.getValue(), 'incidents')) ||
              cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.keys(incidentStatusTypes)
            .filter((s) => s !== 'INVALID')
            .map((s) => ({
              value: s,
              label: t(getTranslationKey(s, 'incidents')),
            })),
        ],
        Cell: ({ cell }) =>
          labelCell({
            name: getLabel(cell.getValue()),
            color: incidentStatusTypes[cell.getValue()]?.color,
          }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions,
      },
      {
        accessorKey: 'workspace',
        header: t(keys.common.WORKSPACE),
        Cell: ({ cell }) => workspaceCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: workspaces,
        enableSorting: false,
      },
      {
        accessorKey: 'dateOfIncident',
        header: t(keys.incidents.DATE_OF_INCIDENT),
        size: 275,
        Cell: ({ cell }) => dateCell({ cell }),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateOfIncidentMin}
            maxDate={dateSelectors.dateOfIncidentMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateOfIncidentMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateOfIncidentMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        Cell: ({ cell }) => dateCell({ cell }),
        size: 275,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
    ],
    [
      dateCell,
      dateSelectors,
      labelCell,
      t,
      textCell,
      userCell,
      userFilterOptions,
      workspaceCell,
      workspaces,
    ],
  );
  return (
    <MTTable
      isLoading={networkStatus !== NetworkStatus.ready}
      data={incidents || []}
      columns={columns}
      rowCount={incidentsCount}
      onRowClick={(incident) =>
        navigate(getRoute(incident.workspaceId, paths.incident, incident?.id))
      }
    />
  );
}
