import React, { useMemo, useState } from 'react';
import { Text } from '../../components/typography';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants/strings';
import { getLocalTime } from '../../utilities/time';
import { assessmentTypes } from '../../constants/strings';
import { Icon } from '@mui/material';
import { useWorkspace } from '../../providers/WorkspaceProvider.jsx';
import { getRoute } from '../../constants/strings';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys.js';
import { getColor } from '../../constants/strings';
import { useTableComponents } from '../../components/tables/MTableComponents.jsx';
import MTTable from '../../components/tables/MTable.jsx';
import { useURLParams } from '../../providers/URLParamProvider.jsx';
import { CustomDateRangeFilter } from '../../components/tables/MTableComponents.jsx';
import { useQueryParams } from '../../hooks/misc.js';

const inspectionsQuery = loader('./InspectionsList.graphql');

export default function InspectionsList({ parent }) {
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { filters, page, pageSize, sort, subfilters } = useURLParams();
  const { getParam } = useQueryParams();
  const [dateSelectors, setDateSelectors] = useState({
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
    dateModifiedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateModifiedMax: getParam('dateCreated')?.split('__')[0] || '',
  });
  const inspectionType = getParam('inspectionType');
  const { labelCell, userFilterOptions } = useTableComponents();

  const allFilters = [
    ...filters,
    {
      field: 'inspectionType',
      operator: !!inspectionType ? 'eq' : 'ne',
      value: !!inspectionType ? `${inspectionType}` : 'INCIDENT',
    },
    { field: 'workspaceId', operator: 'eq', value: `${workspace.id}` },
  ];

  const { loading, data: { inspections = [], inspectionsCount = 0 } = {} } =
    useQuery(inspectionsQuery, {
      skip: !page || !sort || !pageSize,
      variables: {
        options: {
          page,
          pageSize,
          sort,
          filters: [...allFilters],
          subfilters,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'inspectionType',
        header: t(keys.common.TYPE),
        size: 100,
        filterVariant: 'select',
        filterSelectOptions: [
          ...Object.keys(assessmentTypes)
            .filter((f) => f !== 'observation' && f !== 'incident')
            .map((type) => ({
              value: assessmentTypes[type].key,
              label: t(assessmentTypes[type].title),
            })),
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              fontSize: '1.25rem',
              color: getColor(cell?.getValue()?.toLowerCase()),
            }}
          >
            {assessmentTypes[cell.getValue().toLowerCase()]?.icon ||
              'local_shipping'}
          </Icon>
        ),
      },
      {
        accessorFn: (row) => row?.template?.title,
        accessorKey: 'template.title',
        header: t(keys.common.TITLE),
        grow: true,
        enableSorting: false,
        minSize: 250,
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        grow: true,
        size: 120,
        enableSorting: false,
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions,
        Cell: ({ cell }) => <SimpleUserCard size="sm" user={cell.getValue()} />,
      },
      {
        accessorKey: 'isDraft',
        header: t(keys.common.STATUS),
        Header: ({ column }) => (
          <div style={{ textWrap: 'nowrap' }}>{column.columnDef.header}</div>
        ),
        size: 100,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.IN_PROGRESS) },
          { value: 'false', label: t(keys.common.COMPLETE) },
        ],
        Cell: ({ cell }) =>
          labelCell({
            name: !!cell.getValue()
              ? t(keys.common.IN_PROGRESS)
              : t(keys.common.COMPLETE),
            color: !!cell.getValue() ? 'yellow' : 'green',
          }),
      },
      {
        accessorFn: (row) => row.passed,
        header: t(keys.common.PASSED),
        size: 100,
        grow: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { value: 'true', label: t(keys.common.PASSED) },
          { value: 'false', label: t(keys.common.FAILED) },
        ],
        Cell: ({ cell }) => (
          <Icon
            baseClassName="material-icons-outlined"
            sx={{
              color: cell.getValue() ? '#28a745' : '#e9222c',
              fontSize: '1.25rem',
            }}
          >
            {cell.getValue() ? 'check' : 'close'}
          </Icon>
        ),
      },
      {
        accessorKey: 'dateModified',
        header: t(keys.common.DATE_SUBMITTED),
        size: 275,
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {getLocalTime(cell.getValue()).format('MMM Do, YYYY')}
          </Text>
        ),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateModifiedMin}
            maxDate={dateSelectors.dateModifiedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateModifiedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        size: 275,
        Cell: ({ cell }) => (
          <Text noMargin size="sm" weight="semiBold">
            {getLocalTime(cell.getValue()).format('MMM Do, YYYY')}
          </Text>
        ),
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
    ],
    [dateSelectors, labelCell, t, userFilterOptions],
  );

  return (
    <MTTable
      isLoading={loading}
      rightClickNavigation={paths.assessment}
      columns={columns}
      data={inspections || []}
      rowCount={inspectionsCount}
      onRowClick={(assessment) =>
        navigate(getRoute(workspace?.id, paths.assessment, assessment.id))
      }
      onClearAllFilters={() => setDateSelectors({})}
    />
  );
}
