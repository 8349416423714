import React from 'react';
import styles from './MTableComponents.module.scss';
import { TextField, Box } from '@mui/material';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import { Text } from '../typography';
import SimpleUserCard from '../SimpleUserCard';
import { getLocalTime } from '../../utilities/time';
import { useWorkspacePermissions } from '../../providers/WorkspacePermissionsProvider';
import { useCurrentUser } from '../../providers/UserProvider';
import Label from '../action_items/Label';
import defaultLogo from '../../assets/workspace_image.png';

export const CustomDateRangeFilter = ({
  column,
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
}) => {
  const handleMinDateChange = (event) => {
    const value = event.target.value;
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setMinDate(formattedValue);
    column.setFilterValue([formattedValue, maxDate]);
  };

  const handleMaxDateChange = (event) => {
    const value = event.target.value;
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setMaxDate(formattedValue);
    column.setFilterValue([minDate, formattedValue]);
  };
  const clearMinDate = () => {
    setMinDate('');
    column.setFilterValue(['', maxDate]);
  };

  const clearMaxDate = () => {
    setMaxDate('');
    column.setFilterValue([minDate, '']);
  };

  return (
    <Box display="flex" maxWidth={'20rem'} borderBottom={'1px solid gray'}>
      <TextField
        type="date"
        value={minDate || ''}
        onChange={handleMinDateChange}
        label="Min"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          className: !!minDate ? styles.dateSelector : styles.placeholder,
          style: { fontSize: 'small' },
          placeholder: 'Min',
          endAdornment: !!minDate ? (
            <ClearIcon
              className={styles.clear}
              fontSize="1rem"
              onClick={clearMinDate}
            />
          ) : null,
        }}
      />
      <TextField
        type="date"
        value={maxDate || ''}
        label="Max"
        onChange={handleMaxDateChange}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          className: !!maxDate ? styles.dateSelector : styles.placeholder,
          style: { fontSize: 'small' },
          endAdornment: !!maxDate ? (
            <ClearIcon
              className={styles.clear}
              fontSize="1rem"
              onClick={clearMaxDate}
            />
          ) : null,
        }}
      />
    </Box>
  );
};

export const textCell = ({ cell }) => (
  <Text noMargin weight="semiBold" size="sm" truncate>
    {cell.getValue()}
  </Text>
);

export const userCell = ({ cell }) => (
  <SimpleUserCard size="sm" user={cell.getValue()} />
);

export const dateCell = ({ cell }) => (
  <Text noMargin weight="semiBold" size="sm">
    {getLocalTime(cell.getValue()).format('MMMM Do, YYYY HH:mm')}
  </Text>
);
export const dateCellFromNow = ({ cell }) => (
  <Text noMargin weight="semiBold" size="sm">
    {getLocalTime(cell.getValue()).fromNow()}
  </Text>
);

export const userFilterOptions = (data) => {
  return data.map((u) => ({
    label: `${u.firstName} ${u.lastName}`,
    value: `${u.id}`,
  }));
};

export const formatFilters = (originalField, originalValue) => {
  let operator = 'eq';
  let value = originalValue;
  let field = originalField;
  switch (originalField) {
    case 'description':
    case 'title':
      operator = 'iLike';
      value = `%${value}%`;
      break;
    case 'reviewer':
    case 'creator':
    case 'workspace':
      field = `${field}Id`;
      break;
    case 'dateTime':
    case 'dateCreated':
      const [min, max] = originalValue;
      const dateMin =
        min && moment(min).isValid()
          ? moment(min).format('YYYY-MM-DD 00:00:00')
          : null;
      const dateMax =
        max && moment(max).isValid()
          ? moment(max).format('YYYY-MM-DD 23:59:59')
          : null;
      if (dateMin && !dateMax) {
        operator = 'gte';
        value = [dateMin];
      } else if (!dateMin && dateMax) {
        operator = 'lte';
        value = [dateMax];
      } else if (dateMin && dateMax) {
        operator = 'between';
        value = [dateMin, dateMax];
      } else {
        return null;
      }
      break;
    default:
      break;
  }
  return { field, operator, value };
};

export const useTableComponents = () => {
  const { allUsers, workspaceUsers } = useWorkspacePermissions();
  const { isAdmin } = useCurrentUser();
  const users = isAdmin ? allUsers : workspaceUsers;
  const textCell = ({ cell }, color = 'primary') => (
    <Text noMargin weight="semiBold" size="sm" truncate color={color}>
      {cell.getValue()}
    </Text>
  );
  const userCell = ({ cell }) => (
    <SimpleUserCard size="sm" user={cell.getValue()} />
  );
  const dateCell = ({ cell }, color) => {
    return !!cell.getValue() ? (
      <Text noMargin weight="semiBold" size="sm" color={color}>
        {getLocalTime(cell.getValue()).format('YYYY-MM-DD')}
      </Text>
    ) : (
      <Text size="sm" noMargin>
        None
      </Text>
    );
  };
  const dateCellFromNow = ({ cell }) => {
    return !!cell.getValue() ? (
      <Text noMargin weight="semiBold" size="sm">
        {getLocalTime(cell.getValue()).fromNow()}
      </Text>
    ) : (
      <Text size="sm" noMargin>
        None
      </Text>
    );
  };
  const labelCell = ({ name, color }) => {
    return <Label name={name || 'unknown'} color={color || 'grey'} />;
  };

  const userFilterOptions = users?.map((u) => ({
    label: `${u.firstName} ${u.lastName}`,
    value: `${u.id}`,
  }));

  const workspaceCell = ({ cell }) => {
    return (
      <div className={styles.workspace}>
        <img
          src={cell.getValue()?.logoUrl || defaultLogo}
          alt="workspace_img"
          className={styles.workspaceLogo}
        />
        <Text noMargin weight="semiBold" size="sm" truncate>
          {cell.getValue()?.title}
        </Text>
      </div>
    );
  };

  return {
    textCell,
    userCell,
    dateCell,
    dateCellFromNow,
    userFilterOptions,
    labelCell,
    workspaceCell,
  };
};

export const tableStyle = {
  muiTableHeadCellProps: () => ({
    style: {
      fontSize: '12px',
      border: 'none',
    },
  }),
  muiTablePaperProps: () => ({
    style: {
      border: 'none',
      padding: '1rem',
      boxShadow: 'none',
      width: '100%',
      borderRadius: '10px',
    },
  }),
};

export const tableOptions = {
  enableColumnOrdering: true,
  enableColumnResizing: true,
  enableSorting: true,
  manualPagination: true,
  enableGlobalFilter: false,
  manualFiltering: true,
  manualSorting: true,
  enableFilterMatchHighlighting: false,
  enableFacetedValues: true,
};
export const checkDueDateColor = ({ dueDate, status }) => {
  const today = new moment();
  if (status === 'DONE' || status === 'DUPLICATE') {
    return 'secondary';
  }
  if (status !== 'DONE' && dueDate) {
    if (moment(parseInt(dueDate)).isBefore(today, 'day')) {
      return 'red';
    }
    if (
      moment(parseInt(dueDate)).isSame(today, 'day') ||
      moment(parseInt(dueDate)).isSame(today.add(1, 'day'), 'day')
    ) {
      return 'yellow';
    }
  } else {
    return 'primary';
  }
};
