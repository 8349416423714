import { loader } from 'graphql.macro';
import MTTable from '../../components/tables/MTable';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import { useURLParams } from '../../providers/URLParamProvider';
import {
  useTableComponents,
  CustomDateRangeFilter,
  checkDueDateColor,
} from '../../components/tables/MTableComponents';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/typography';
import { keys } from '../../utilities/translator/translation_keys';
import styles from './ActionItemList.module.scss';
import { Button } from '../../components';
import { useActionItemModal } from '../../hooks/misc';
import { Form } from 'react-bootstrap';
import { useQueryParams } from '../../hooks/misc';
import { useCurrentUser } from '../../providers/UserProvider';
import {
  priorityStringLookup,
  statusStringLookup,
} from '../../components/action_items/action_item_utilities';
import Label from '../../components/action_items/Label';
import SimpleTabSystem from '../../components/SimpleTabSystem';
import { useApolloClient, gql } from '@apollo/client';
import { fetchAllData } from '../../utilities/excel_export/table_excel_export';
import { exportToExcel } from '../../components/excel/ExcelExport';
import ProgressModal from '../../components/modals/ProgressModal';
import { showToast } from '../../graphql/cache/modal';
import { toastVariant } from '../../constants/misc';
import format from 'date-fns/format';
import {
  excelHeaderLookup,
  formatActionItem,
} from '../../utilities/excel_export/data_converter';
import { useWorkspace } from '../../providers/WorkspaceProvider';

const actionItemQuery = loader('./ActionItemList.fetch.graphql');
const maxLabels = 3;

export default function ActionItemList() {
  const { page, pageSize, filters, sort } = useURLParams();
  const { t } = useTranslation();
  const { openActionItemModal } = useActionItemModal();
  const [selectedTab, setSelectedTab] = useState('open');
  const { upsertParam, upsertParams, deleteParam, getParam, deleteParams } =
    useQueryParams();
  const { isWorkspaceAdmin } = useWorkspace();
  const [dateSelectors, setDateSelectors] = useState({
    dueDateMin: getParam('dueDate')?.split('__')[0] || '',
    dueDateMax: getParam('dueDate')?.split('__')[1] || '',
    dateCreatedMin: getParam('dateCreated')?.split('__')[0] || '',
    dateCreatedMax: getParam('dateCreated')?.split('__')[1] || '',
  });
  const { user } = useCurrentUser();
  const [isFetchingAllData, setIsFetchingAllData] = useState(false);
  const [progress, setProgress] = useState(0);

  const { dateCell, textCell, userCell, userFilterOptions, labelCell } =
    useTableComponents();
  const client = useApolloClient();
  const {
    refetch,
    networkStatus,
    previousData: { actionItemsCount: oldCount } = {},
    data: { actionItems = [], actionItemsCount = 0 } = {},
  } = useQuery(actionItemQuery, {
    skip: !page || !pageSize || !sort,
    variables: {
      options: {
        page,
        pageSize,
        filters,
        sort,
      },
    },
  });
  const getChecks = () => {
    const assignee = getParam('assignee');
    const allWorkspaces = getParam('allWorkspaces');
    if (assignee === `${user.id}` && !!allWorkspaces) {
      return true;
    }
    return false;
  };
  const assignedToMeCheck = getChecks();

  const tabOptions = [
    { title: t(keys.action_items.OPEN), key: 'open' },
    { title: t(keys.action_items.CLOSED), key: 'closed' },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: t(keys.common.TITLE),
        Cell: ({ cell }) => textCell({ cell }),
      },
      {
        accessorKey: 'assignee',
        header: t(keys.common.ASSIGNEE),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions,
      },
      {
        accessorKey: 'priority',
        header: t(keys.action_items.PRIORITY),
        filterVariant: 'select',
        size: 120,
        filterSelectOptions: Object.entries(priorityStringLookup).map(
          ([key, v]) => ({ label: t(v.name), value: key }),
        ),
        Cell: ({ cell }) => {
          return (
            <Text
              noMargin
              weight="semiBold"
              size="sm"
              truncate
              color={priorityStringLookup[cell.getValue()].color}
            >
              {t(priorityStringLookup[cell.getValue()].name)}
            </Text>
          );
        },
      },
      {
        accessorKey: 'status',
        header: t(keys.common.STATUS),
        size: 120,
        filterVariant: 'select',
        filterSelectOptions: Object.entries(statusStringLookup).map(
          ([key, v]) => ({ label: t(v.title), value: key }),
        ),
        Cell: ({ cell }) =>
          labelCell({
            name: t(statusStringLookup[cell.getValue()].title),
            color: statusStringLookup[cell.getValue()].color,
          }),
      },
      {
        accessorKey: 'creator',
        header: t(keys.common.CREATOR),
        enableSorting: false,
        Cell: ({ cell }) => userCell({ cell }),
        filterVariant: 'select',
        filterSelectOptions: userFilterOptions,
      },
      {
        accessorKey: 'dueDate',
        header: t(keys.action_items.DUE_DATE),
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          const color = checkDueDateColor({ dueDate: cell.getValue(), status });
          return dateCell({ cell }, color);
        },
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dueDateMin}
            maxDate={dateSelectors.dueDateMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dueDateMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dueDateMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'dateCreated',
        header: t(keys.common.DATE_CREATED),
        Cell: ({ cell }) => dateCell({ cell }),
        size: 275,
        grow: true,
        Filter: ({ column }) => (
          <CustomDateRangeFilter
            column={column}
            minDate={dateSelectors.dateCreatedMin}
            maxDate={dateSelectors.dateCreatedMax}
            setMinDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMin: value })
            }
            setMaxDate={(value) =>
              setDateSelectors({ ...dateSelectors, dateCreatedMax: value })
            }
          />
        ),
      },
      {
        accessorKey: 'labels',
        header: t(keys.common.LABELS),
        enableColumnFilter: false,
        enableSorting: false,
        size: 300,
        Cell: ({ cell }) => {
          const labels = cell.getValue() || [];
          return (
            <div className={styles.labelContainer}>
              {labels?.slice(0, maxLabels).map((label) => (
                <Label
                  key={label.id}
                  className={styles.label}
                  name={label.name}
                  color={label.color.toLowerCase()}
                />
              ))}
              {labels?.length > maxLabels && (
                <Text
                  size="sm"
                  weight="semiBold"
                  noMargin
                  color="accentPrimary"
                >{`+ ${labels.length - maxLabels}`}</Text>
              )}
            </div>
          );
        },
      },
    ],
    [
      dateCell,
      dateSelectors,
      labelCell,
      t,
      textCell,
      userCell,
      userFilterOptions,
    ],
  );

  const handleExport = async () => {
    const query = gql`
      ${actionItemQuery}
    `;
    await fetchAllData({
      filters,
      query,
      client,
      setIsFetching: setIsFetchingAllData,
      table: 'actionItems',
      count: 'actionItemsCount',
      setProgress,
    }).then((response, errors) => {
      if (errors?.length) {
        showToast({
          title: 'Error Exporting Data',
          variant: toastVariant.danger,
          message: `An error occurred: ${errors[0].message}`,
        });
      }
      if (response?.length) {
        exportToExcel({
          data: response.map((d) => formatActionItem(d)),
          sheetName: 'Action Item Data',
          exportedBy: `${user.firstName} ${user.lastName}`,
          type: 'Action Item',
          fileName: `Action_Item_Export_${format(new Date(), 'yyyy-MM-dd')}`,
          reportType: ['Report Type', 'Action Item Table Export'],
          headerMap: excelHeaderLookup,
        });
      }
    });
  };

  useEffect(() => {
    if (selectedTab === 'open') {
      upsertParam('open', true);
    } else {
      deleteParam('open');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <>
      <MTTable
        isLoading={networkStatus !== NetworkStatus.ready}
        data={actionItems}
        columns={columns}
        onRowClick={(actionItem) =>
          openActionItemModal(actionItem.id, null, refetch)
        }
        header={
          <div className={styles.container}>
            <div className={styles.header}>
              <Text size="lg" weight="bold" color="accentPrimary">
                {t(keys.common.ACTION_ITEMS)}
              </Text>
              <div className={styles.buttons}>
                <Button
                  variant="primary"
                  icon="add"
                  size="md"
                  testId="action-item"
                  value={t(keys.common.NEW)}
                  onClick={() => {
                    openActionItemModal(null, 'VEHICLE', refetch);
                  }}
                />
                {isWorkspaceAdmin && (
                  <Button
                    variant="primary"
                    outlined
                    size="md"
                    testId="action-item"
                    value={t(keys.action.EXPORT)}
                    onClick={handleExport}
                  />
                )}
              </div>
            </div>
            <SimpleTabSystem
              options={tabOptions}
              selected={selectedTab}
              setSelected={setSelectedTab}
              navigation={false}
            />
          </div>
        }
        rowCount={actionItemsCount || oldCount}
        customToolbar={
          <div className={styles.check}>
            <Form.Check
              size={'small'}
              checked={assignedToMeCheck}
              onChange={(e) => {
                if (e.target.checked) {
                  upsertParams({ assignee: user.id, allWorkspaces: true });
                } else {
                  deleteParams(['assignee', 'allWorkspaces']);
                }
                refetch();
              }}
            />
            <Text noMargin size="sm">
              {t(keys.action_items.ASSIGNED_TO_ME)}
            </Text>
          </div>
        }
      />
      <ProgressModal
        show={isFetchingAllData}
        progress={progress}
        title="Exporting Data"
        message="One moment while we fetch your data. Please don't close this window!"
        icon="import_export"
      />
    </>
  );
}
