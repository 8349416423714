import { createContext, useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  formatFilters,
  formatSubFilter,
  subFilterParams,
} from '../components/tables/table';
import { useWorkspace } from './WorkspaceProvider';
import { nonFilterParams } from '../components/tables/table';
import { useQueryParams } from '../hooks/misc';
import { useCurrentUser } from './UserProvider';
import { companyWideTable } from '../constants/strings';
const URLParamContext = createContext();
export const URLParamProvider = ({ children }) => {
  const location = useLocation();
  const { workspaceId, isWorkspaceRoute } = useWorkspace();
  const { deleteParam, getParam } = useQueryParams();
  const { user } = useCurrentUser();
  const hasAssignee = getParam('assignee') === `${user.id}`;
  const allWorkspaces = getParam('allWorkspaces');
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    if (!hasAssignee && !!allWorkspaces) {
      deleteParam('allWorkspaces');
    }
  }, [allWorkspaces, deleteParam, hasAssignee]);

  const getParams = useMemo(() => {
    const queryParams = {};
    const segment =
      window.location.pathname.split('/')[isWorkspaceRoute ? 3 : 1];
    const subfilters = [];

    const filters = companyWideTable.includes(segment)
      ? []
      : [{ field: 'workspaceId', operator: 'eq', value: `${workspaceId}` }];

    if (segment === 'actionitems') {
      filters.push({
        field: 'status',
        operator: getParam('open') === 'true' ? 'not' : 'or',
        value: ['DONE', 'DUPLICATE'],
      });
    }
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    queryParams.pageIndex = parseInt(queryParams.pageIndex);
    queryParams.pageSize = parseInt(queryParams.pageSize);

    for (const [key, value] of Object.entries(queryParams)) {
      let newValue = undefined;
      if (!nonFilterParams.includes(key) && value !== '__' && !!value) {
        if (value.includes('__')) {
          newValue = value.split('__');
        }
        const filter = formatFilters(key, newValue || value);
        filters.push(filter);
      }
      if (subFilterParams.includes(key) && !!value) {
        const subfilter = formatSubFilter(key, value);
        subfilters.push(subfilter);
      }
      if (key === 'allWorkspaces' && hasAssignee) {
        const index = filters.findIndex(
          (filter) => filter.field === 'workspaceId',
        );
        if (index !== -1) {
          filters.splice(index, 1);
        }
      }
    }
    queryParams.sort = [
      {
        field: queryParams.sort,
        order:
          queryParams.desc === 'true' ? 'desc NULLS last' : 'asc NULLS last',
      },
    ];

    queryParams.filters = filters;
    queryParams.subfilters = subfilters;
    return queryParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAssignee, location.search, workspaceId, params]);

  const { pageSize, pageIndex, filters, sort, subfilters } = getParams || {};

  const tableParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};

    searchParams.forEach((value, key) => {
      if (key === 'pageIndex' || key === 'pageSize') {
        params[key] = parseInt(value);
      }
      if (value === '__') {
        deleteParam(key);
      }
      if (value.includes('__')) {
        params[key] = value.split('__');
      } else {
        params[key] = value;
      }
    });

    const tableFilters = Object.entries(params)
      .filter(([key]) => !nonFilterParams.includes(key) && !key.includes('.'))
      .map(([key, value]) => ({ id: key, value }));

    const tablePageIndex = params.pageIndex || 0;
    const tablePageSize = params.pageSize || 10;

    return { tableFilters, tablePageIndex, tablePageSize };
  }, [deleteParam, location.search]);

  const { tableFilters, tablePageIndex, tablePageSize } = tableParams || {};

  return (
    <URLParamContext.Provider
      value={{
        pageSize,
        page: pageIndex + 1,
        filters,
        sort,
        subfilters,
        tableFilters,
        tablePageIndex,
        tablePageSize,
      }}
    >
      {children}
    </URLParamContext.Provider>
  );
};
export const useURLParams = () => useContext(URLParamContext);
