import { loader } from 'graphql.macro';
import styles from './ObservationPage.module.scss';
import { useParams } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import { Text } from '../../components/typography';
import { getLocalTime } from '../../utilities/time';
import { openFileViewer } from '../../graphql/cache/modal';
import { Button } from '../../components';
import { useCurrentUser } from '../../providers/UserProvider';
import { useMutation } from '@apollo/client';
import SimpleUserCard from '../../components/SimpleUserCard';
import ActionItemCard from '../../components/action_items/ActionItemCard';
import { useActionItemModal } from '../../hooks/misc';
import Icon from '../../components/Icon';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { customColors } from '../../utilities';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  keys,
  getTranslationKey,
} from '../../utilities/translator/translation_keys';
import { observationStatus } from '../../utilities/observations';
import { imageExtensions } from '../../utilities/files';
import SimpleFileCard from '../../components/files/SimpleFileCard';
import { useNavigate } from 'react-router-dom';
import { getRoute, paths } from '../../constants/strings';
import { useEffect } from 'react';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';

const observationQuery = loader('./ObservationList.fetch.graphql');
const updateObservationMutation = loader('./ObservationList.update.graphql');
const addActionItemMutation = loader('./ObservationList.addActionItem.graphql');

export default function ObservationPage() {
  const { id } = useParams();
  const { user, isAdmin } = useCurrentUser();
  const [updateObservation] = useMutation(updateObservationMutation);
  const { openActionItemModal } = useActionItemModal();
  const [addActionItem] = useMutation(addActionItemMutation);
  const observationId = parseInt(id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal, openConfirmationModal } = useModal();

  const {
    refetch,
    loading,
    networkStatus,
    data: { observations: [observation] = [{}] } = {},
  } = useQuery(observationQuery, {
    skip: !id,
    fetchPolicy: 'network-only',
    variables: {
      options: {
        filters: { field: 'id', operator: 'eq', value: `${id}` },
      },
    },
  });

  const questions = observation?.items ? JSON.parse(observation?.items) : [];
  const {
    dateTime,
    description,
    location,
    images,
    reviewer,
    dateCreated,
    dateReviewed,
    participants,
    creator,
    actionItems,
    workspace,
    negative,
    notes,
    status,
    incident,
    closer,
    dateClosed,
    private: privateObservation,
  } = observation || {};

  const [editedNotes, setEditedNotes] = useState(notes);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const isReviewed = !!reviewer;
  const readOnly = status === observationStatus.COMPLETE.key || !isAdmin;
  const isCreator = user?.id === creator?.id;

  const isClosed = status === observationStatus.COMPLETE.key;

  const ready = networkStatus === NetworkStatus.ready;

  useEffect(() => {
    setEditedNotes(notes);
  }, [notes]);

  return !ready ? (
    <Spinner variant="primary" animation="border" />
  ) : (
    <div className={styles.card}>
      <div className={negative ? styles.red : styles.green} />
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.header}>
            <Text weight="bold" color="accentPrimary" size="lg" noMargin>
              {user?.company?.observationName
                ? user.company?.observationName + ' Details'
                : t(keys.observations.OBSERVATION_DETAILS)}
            </Text>
            <div className={styles.icons}>
              {!!incident ? (
                <Icon style={{ fontSize: '2rem' }} color="red">
                  fmd_bad
                </Icon>
              ) : null}
              <FlagCircleIcon
                sx={{
                  fontSize: '2rem',
                  color: negative
                    ? customColors.RED_LIGHT
                    : customColors.GREEN_LIGHT,
                }}
              />
            </div>
          </div>
          <div className={styles.status}>
            <Text
              noMargin
              weight="semiBold"
              size="lg"
              color={observationStatus[status]?.color}
            >
              {t(getTranslationKey(status, 'common'))?.toUpperCase() ?? ''}
            </Text>
            {privateObservation ? (
              <Icon opacity={'0.6'}>visibility_off</Icon>
            ) : null}
          </div>
          {isReviewed ? (
            <div className={styles.closed}>
              <Text size="sm" noMargin>
                {t(keys.observations.ACKNOWLEDGED_BY)}{' '}
                <span>
                  {reviewer.firstName} {reviewer.lastName}{' '}
                </span>
                {`${getLocalTime(dateReviewed).format(
                  'dddd MMMM DD, YYYY HH:mm',
                )}`}
              </Text>
            </div>
          ) : null}
          {isClosed ? (
            <div className={styles.closed}>
              <Text size="sm" noMargin>
                {t(keys.incidents.CLOSED_BY)}{' '}
                <span>
                  {closer.firstName} {closer.lastName}{' '}
                </span>
                {`${getLocalTime(dateClosed).format(
                  'dddd MMMM DD, YYYY HH:mm',
                )}`}
              </Text>
            </div>
          ) : null}
          <br />
          <Text weight="semiBold" noMargin>
            {t(keys.observations.DATE_AND_TIME)}
          </Text>
          <Text weight="semiBold" color="accentPrimary">
            {getLocalTime(dateTime).format('dddd MMMM DD, YYYY HH:mm')}
          </Text>
          <Text weight="semiBold" noMargin>
            {t(keys.common.LOCATION)}
          </Text>
          <Text color="accentPrimary" weight="semiBold">
            {location || t(keys.assessments.NO_ANSWER_PROVIDED)}
          </Text>
          <Text weight="semiBold" noMargin>
            {t(keys.common.DESCRIPTION)}
          </Text>
          <Text weight="semiBold" color="accentPrimary">
            {description}
          </Text>
          {questions.map(({ id, value, title, description }) => (
            <div key={id}>
              <Text weight="semiBold" noMargin>
                {title}
              </Text>
              <Text color="secondary" noMargin>
                {description}
              </Text>
              <Text weight="semiBold" color="accentPrimary">
                {Array.isArray(value) ? value?.join(', ') : value}
              </Text>
            </div>
          ))}
          <Text weight="semiBold" noMargin>
            {t(keys.common.FILES)}
          </Text>
          {images?.length ? (
            <div className={styles.images}>
              {images.map((image, idx) => {
                const extension = image.imageUrl.split('.').pop().toLowerCase();
                const isImage = imageExtensions.includes(extension);
                const file = {
                  ...image,
                  fileType: extension,
                  url: image.imageUrl,
                };
                return isImage ? (
                  <img
                    key={image.imageUrl}
                    onClick={() => openFileViewer(file)}
                    className={styles.image}
                    src={image.imageUrl}
                    alt={`observation-${idx}`}
                  />
                ) : (
                  <SimpleFileCard
                    extension={extension}
                    fileName={`${extension.toUpperCase()}-Attachment`}
                    file={file}
                  />
                );
              })}
            </div>
          ) : (
            <Text weight="semiBold" color="secondaryLight">
              {t(keys.common.NONE)}
            </Text>
          )}
          <Text noMargin weight="semiBold">
            {t(keys.observations.TEAM_MEMBERS_PRESENT)}
          </Text>
          {!participants?.length ? (
            <Text
              weight="semiBold"
              className={styles.text}
              color="secondaryLight"
            >
              {t(keys.common.NONE)}
            </Text>
          ) : (
            <div className={styles.team}>
              {participants.map((user) => (
                <SimpleUserCard user={user} key={user?.id} />
              ))}
            </div>
          )}
          <div className={styles.header}>
            <Text weight="semiBold" noMargin>
              {t(keys.common.NOTES)}
            </Text>
            {!isEditingNotes && !readOnly && (
              <Icon onClick={() => setIsEditingNotes(true)} opacity={'0.6'}>
                edit
              </Icon>
            )}
          </div>
          {!isEditingNotes ? (
            <Text
              color={!!notes ? 'accentPrimary' : 'secondaryLight'}
              weight="semiBold"
              style={styles.prewrap}
            >
              {notes ?? t(keys.common.NONE)}
            </Text>
          ) : (
            <Form.Control
              as="textarea"
              rows={5}
              className={styles.prewrap}
              value={editedNotes}
              onChange={(e) => setEditedNotes(e.target.value)}
              onBlur={() => {
                updateObservation({
                  variables: { id: observationId, notes: editedNotes },
                });
                setIsEditingNotes(false);
              }}
            />
          )}
        </div>
        <div className={styles.right}>
          <Text weight="semiBold" size="lg">
            {t(keys.common.INFORMATION)}
          </Text>
          {!!incident ? (
            <div>
              <Text
                noMargin
                weight="semiBold"
                className={styles.incidentText}
                color="secondary"
              >
                {`${t(keys.boards.INCIDENT_REPORTED)}`}
              </Text>
              <div className={styles.text}>
                <div className={styles.closed}>
                  <Icon color="red">fmd_bad</Icon>
                  <Text
                    noMargin
                    color="red"
                    hover={isAdmin || isCreator}
                    weight="semiBold"
                    onClick={() => {
                      if (isAdmin || isCreator) {
                        navigate(
                          getRoute(
                            incident?.workspace?.id,
                            paths.incident,
                            incident?.id,
                          ),
                        );
                      }
                    }}
                  >
                    {incident?.title}
                  </Text>
                </div>
              </div>
              <div className={styles.sectionLine} />
            </div>
          ) : null}
          <Text weight="semiBold" color="secondary" noMargin>
            {t(keys.observations.OBSERVER)}
          </Text>
          {!!!creator ? (
            <div className={styles.closed}>
              <Icon color="disabled">help</Icon>
              <Text weight="semiBold" noMargin>
                {t(keys.observations.ANONYMOUS)}
              </Text>
            </div>
          ) : (
            <SimpleUserCard
              className={styles.userCard}
              mediumAvatar
              user={creator}
            />
          )}
          <div className={styles.sectionLine} />
          <Text noMargin weight="semiBold" color="secondary">
            {t(keys.common.DATE_CREATED)}
          </Text>
          <Text weight="semiBold" noMargin>
            {getLocalTime(dateCreated).format('dddd MMMM DD, YYYY HH:mm')}
          </Text>
          <div className={styles.sectionLine} />
          {!isReviewed && !readOnly && (
            <div className={styles.buttons}>
              <Button
                value={t(keys.action.DISMISS)}
                variant="danger"
                onClick={() => {
                  openConfirmationModal({
                    title: t(keys.observations.INVALID_VARIABLE, {
                      variable: t(keys.common.OBSERVATION),
                    }),
                    description: t(keys.observations.INVALID_CONFIRMATION),
                    variant: 'danger',
                    buttonText: t(keys.observations.INVALID),
                    onSubmit: () => {
                      updateObservation({
                        variables: {
                          id: observationId,
                          reviewerId: user.id,
                          status: observationStatus.INVALID.key,
                        },
                      }).then(() =>
                        navigate(getRoute(workspace?.id, paths.observations)),
                      );
                    },
                  });
                }}
              />
              <Button
                onClick={() => {
                  updateObservation({
                    variables: {
                      id: observationId,
                      reviewerId: user.id,
                      status: observationStatus.IN_PROGRESS.key,
                    },
                  }).then(() => refetch());
                }}
                value={t(keys.observations.ACKNOWLEDGE)}
              />
            </div>
          )}
          {isReviewed && (
            <>
              {isReviewed && !loading && (
                <div className={styles.actionItsemContainer}>
                  <div className={styles.header}>
                    <Text
                      color="accentPrimary"
                      size="lg"
                      weight="semiBold"
                      noMargin={!actionItems?.length}
                    >
                      {t(keys.common.ACTION_ITEMS)}
                    </Text>
                    {!readOnly && (
                      <Icon
                        color="primary"
                        hover
                        onClick={() => {
                          addActionItem({
                            variables: {
                              title: `Observation ${observationId}`,
                              description,
                              type: 'OBSERVATION',
                              observationId,
                              workspaceId: workspace?.id,
                            },
                          }).then(
                            ({
                              data: {
                                addActionItem: { id },
                              },
                            }) => {
                              openActionItemModal(id, 'OBSERVATION', refetch);
                            },
                          );
                        }}
                      >
                        add
                      </Icon>
                    )}
                  </div>
                  {actionItems?.length ? (
                    <div className={styles.actionItems}>
                      {actionItems.map((a) => (
                        <ActionItemCard
                          key={a.id}
                          actionItem={a}
                          onClick={() => {
                            openActionItemModal(a.id);
                          }}
                          className={styles.actionItemCard}
                        />
                      ))}
                    </div>
                  ) : (
                    <Text weight="semiBold" color="secondaryLight">
                      {t(keys.common.NONE)}
                    </Text>
                  )}
                </div>
              )}
              {!isClosed && !readOnly && !!reviewer && (
                <div className={styles.buttons} style={{ marginTop: 'auto' }}>
                  {!incident && (
                    <Button
                      value={t(keys.observations.ESCALATE)}
                      variant="danger"
                      onClick={() => {
                        openModal({
                          modalName: modals.createIncident,
                          variables: {
                            title: `Observation ${id}`,
                            participants: !observation?.creator
                              ? [...participants]
                              : participants
                                  .map(({ id }) => id)
                                  .includes(creator?.id)
                              ? [...participants]
                              : [...participants, creator],
                            observation,
                            type: 'Health and Safety',
                            subtype: 'Near Miss',
                            description,
                          },
                        });
                      }}
                    />
                  )}
                  <Button
                    value={t(keys.action_items.CLOSE)}
                    variant="success"
                    onClick={() => {
                      updateObservation({
                        variables: {
                          id: observationId,
                          closerId: user.id,
                          status: observationStatus.COMPLETE.key,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
