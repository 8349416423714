import styles from './IncidentReport.module.scss';
import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { defaultNavbarState, navbarVar } from '../../graphql/cache/navbar';
import { Text } from '../../components/typography';
import { Icon } from '@mui/material';
import {
  employeeDetailQuestions,
  incidentDetailQuestions,
  investigationQuestions,
} from '../../utilities/incidents';
import Accordian from '../../components/Accordian';
import { Button } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  assessmentTypes,
  incidentStatus,
  paths,
} from '../../constants/strings';
import { showToast, signatureModalVar } from '../../graphql/cache/modal';
import { toastVariant, toastLength } from '../../constants/misc';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import EmptyStateView from '../../components/empty_state_view/EmptyStateView';
import noImage from '../../assets/icons/image_blank.png';
import noInspections from '../../assets/empty_state_images/inspections.svg';
import IncidentSidebar from './IncidentSidebar';
import { Table } from 'react-bootstrap';
import SimpleUserCard from '../../components/SimpleUserCard';
import { getLocalTime } from '../../utilities/time';
import Label from '../../components/action_items/Label';
import { Form } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import FormQuestion from '../../components/forms/FormQuestion';
import { useActionItemModal, useIsMobile } from '../../hooks/misc';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { getRoute } from '../../constants/strings';
import DropdownCombo from '../../components/dropdowns/DropdownCombo';
import VehicleCard from '../../components/vehicles/VehicleCard';
import { useCurrentUser } from '../../providers/UserProvider';
import { useModal } from '../../providers/ModalProvider';
import { modals } from '../../providers/modals';
import { dropdownTypes } from '../../components/dropdowns/dropdown';
import { getTranslationKey } from '../../utilities/translator/translation_keys';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { DropdownText } from '../../components/dropdowns/DropdownText';
import { incidentTypes } from '../../utilities/incidents';
import IncidentImageCard from './IncidentImageCard';
import SimpleFileCard from '../../components/files/SimpleFileCard';
import { imageExtensions } from '../../utilities/files';
import moment from 'moment';

const incidentQuery = loader('./IncidentReport.fetch.graphql');
const publishMutation = loader('./IncidentReport.publish.graphql');
const addIncidentImageMutation = loader('./IncidentReport.addImage.graphql');
const incidentInspectionQuery = loader('./IncidentReport.inspections.graphql');
const actionItemQuery = loader('./IncidentReport.actionItems.graphql');
const addActionItemMutation = loader('./IncidentReport.addActionItem.graphql');
const incidentUpdateMutation = loader('./IncidentReport.update.graphql');
const incidentImageQuery = loader('./IncidentReport.getImages.graphql');
const deleteIncidentImageMutation = loader(
  './IncidentReport.deleteImage.graphql',
);
const reviewerIncidentMutation = loader('./IncidentReport.review.graphql');

const approvedStatuses = [
  incidentStatus.investigation,
  incidentStatus.complete,
];

const notesStatuses = [
  incidentStatus.complete,
  incidentStatus.invalid,
  incidentStatus.closed,
  incidentStatus.investigation,
];

export default function IncidentReport() {
  const { id } = useParams();
  const { workspace, workspaceVehicles, workspaceId, observationTemplate } =
    useWorkspace();
  const navigate = useNavigate();
  const { openActionItemModal } = useActionItemModal();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [publishIncident] = useMutation(publishMutation);
  const [addIncidentImage] = useMutation(addIncidentImageMutation);
  const [addActionItem] = useMutation(addActionItemMutation);
  const [updateIncident] = useMutation(incidentUpdateMutation);
  const [reviewIncident] = useMutation(reviewerIncidentMutation);
  const maxLabels = 3;
  const { user, isAdmin } = useCurrentUser();
  const { openModal, openConfirmationModal } = useModal();
  const { t } = useTranslation();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const incidentId = parseInt(id);
  const [deleteIncidentImage] = useMutation(deleteIncidentImageMutation);

  useEffect(() => {
    navbarVar({ ...defaultNavbarState, showMenu: false });
  }, []);

  const { loading: loadingInspections, data: { inspections } = {} } = useQuery(
    incidentInspectionQuery,
    {
      skip: !id,
      fetchPolicy: 'cache-and-network',
      variables: {
        options: {
          filters: [
            {
              field: 'incidentId',
              operator: 'eq',
              value: [`${id}`],
            },
          ],
        },
      },
    },
  );
  const { refetch: refetchImages, data: { incidentImages } = {} } = useQuery(
    incidentImageQuery,
    {
      skip: !id,
      fetchPolicy: 'cache-and-network',
      variables: {
        incidentId: `${id}`,
      },
    },
  );

  const {
    loading: loadingIncident,
    refetch: refetchIncident,
    data: { incidents: [incident] = [{}] } = {},
  } = useQuery(incidentQuery, {
    skip: !id,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: `${id}`,
    },
  });
  const {
    loading: loadingActionItems,
    refetch: refetchActionItems,
    data: incidentActionItems,
  } = useQuery(actionItemQuery, {
    skip: !id,
    variables: {
      incidentId: `${id}`,
      actionItemType: 'INCIDENT',
    },
  });
  const [title, setTitle] = useState(incident?.title);

  useEffect(() => {
    setTitle(incident?.title);
  }, [incident.title]);

  const [notes, setNotes] = useState(incident?.notes);

  const submitDisabled = useMemo(() => {
    const requiredKeys = Object.values(incidentDetailQuestions)
      .filter((v) => !v.legacyInput)
      .map((v) => v.convertToQuery);
    return requiredKeys.some((k) => incident[k] === null);
  }, [incident]);
  const isMobile = useIsMobile();

  const { observation } = incident || {};

  const combinedImages = [
    ...(observation?.images || []),
    ...(incidentImages || []),
  ];

  const isImageFile = (extension) => {
    return imageExtensions.includes(extension);
  };

  const allFiles = combinedImages.filter((item) => {
    const extension = item.imageUrl.split('.').pop().toLowerCase();
    return !isImageFile(extension);
  });

  const allImages = combinedImages.filter((item) => {
    const extension = item.imageUrl.split('.').pop().toLowerCase();
    return isImageFile(extension);
  });

  return (
    <div className={styles.split}>
      <div
        className={classNames([
          styles.container,
          sidebarOpen && isMobile && styles.hide,
        ])}
      >
        {!!incident?.id && (
          <div>
            <Text size="lg" color="accentPrimary" weight="bold">
              {user.company?.incidentName || t(keys.common.INCIDENT)}
            </Text>
            <div className={styles.header}>
              {isEditingTitle ? (
                <div style={{ width: '100%' }}>
                  <Form.Control
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() =>
                      updateIncident({ variables: { id: incidentId, title } })
                    }
                  />
                  <div className={styles.row}>
                    <DropdownText
                      required={true}
                      highlight
                      placeholder={t(keys.incidents.TYPE)}
                      selected={incident.type}
                      onChange={(newType) =>
                        updateIncident({
                          variables: {
                            id: incidentId,
                            type: newType,
                            subtype: '',
                          },
                        }).then(() => refetchIncident())
                      }
                      items={Object.keys(incidentTypes)}
                    />
                    <DropdownText
                      highlight
                      placeholder={t(keys.incidents.SUBTYPE)}
                      required={true}
                      selected={incident.subtype}
                      onChange={(newType) =>
                        updateIncident({
                          variables: { id: incidentId, subtype: newType },
                        }).then(() => refetchIncident())
                      }
                      items={incidentTypes[incident.type]}
                      disabled={!incident.type}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <Text noMargin weight="bold" size="lg" color="primary">
                    {incident.title}
                  </Text>
                  <Text noMargin weight="bold" size="md" color="secondary">
                    {`${t(
                      getTranslationKey(incident?.type, 'incidents'),
                    )} - ${t(
                      getTranslationKey(incident?.subtype, 'incidents'),
                    )}` || `${incident.type} - ${incident.subtype}`}
                  </Text>
                </div>
              )}
              {incident.status === 'INITIATED' && (
                <Icon
                  onClick={() => setIsEditingTitle(!isEditingTitle)}
                  baseClassName="material-icons-outlined"
                  style={{ opacity: 0.5, cursor: 'pointer' }}
                >
                  {isEditingTitle ? 'save' : 'edit'}
                </Icon>
              )}
            </div>
            <div
              onClick={() => {
                setSidebarOpen(true);
              }}
              className={classNames([styles.hideDesktop])}
            >
              <Icon baseClassName="material-icons-outlined">more_horiz</Icon>
            </div>
            <br />
            <div className={styles.bottomContainer}>
              {!!incident?.id ? (
                <Accordian
                  icon="summarize"
                  title={t(keys.common.INFORMATION)}
                  message={
                    incident?.status === 'INITIATED'
                      ? t(keys.common.IN_PROGRESS)
                      : t(keys.common.COMPLETE)
                  }
                >
                  <div className={styles.content}>
                    <Text
                      weight="semiBold"
                      size="lg"
                      noMargin
                      color="accentPrimary"
                    >
                      {t(keys.incidents.INCIDENT_DETAILS)}
                    </Text>
                    {incidentDetailQuestions.map((question) => (
                      <FormQuestion
                        key={question.id}
                        question={question}
                        objectKey={question.convertToQuery}
                        value={incident[question.convertToQuery]}
                        update={updateIncident}
                        parentId={incident.id}
                        readOnly={incident.status !== incidentStatus.initiated}
                        legacyInput={question.legacyInput}
                      />
                    ))}
                    <br />
                    {incident.type === 'Health and Safety' ? (
                      <div>
                        <Text
                          weight="semiBold"
                          size="lg"
                          color="accentPrimary"
                          noMargin
                        >
                          {t(keys.incidents.EMPLOYEE_DETAILS)}
                        </Text>
                        {employeeDetailQuestions.map((question) => (
                          <div key={question.id}>
                            <FormQuestion
                              question={question}
                              objectKey={question.convertToQuery}
                              value={incident[question.convertToQuery]}
                              update={updateIncident}
                              parentId={incident.id}
                              readOnly={
                                incident.status !== incidentStatus.initiated
                              }
                              legacyInput={question.legacyInput}
                            />
                          </div>
                        ))}
                        <br />
                      </div>
                    ) : null}

                    <Text weight="semiBold" size="lg" color="accentPrimary">
                      {t(keys.incidents.ADMINISTRATIVE_DETAILS)}
                    </Text>
                    <div>
                      {incident.status === incidentStatus.initiated ? (
                        <DropdownCombo
                          type={dropdownTypes.ASSET}
                          onRemove={() => {
                            updateIncident({
                              variables: { id: incidentId, vehicleId: null },
                            });
                            refetchIncident();
                          }}
                          title={t(keys.incidents.ASSET_SELECTION)}
                          items={workspaceVehicles}
                          onChange={(newVehicle) => {
                            updateIncident({
                              variables: {
                                id: incident?.id,
                                vehicleId: newVehicle?.id,
                              },
                            });
                          }}
                          selected={incident?.vehicle}
                          optional={incident.type !== 'Asset'}
                        />
                      ) : (
                        incident?.vehicle && (
                          <div>
                            <Text weight="bold">
                              {t(keys.incidents.ASSET_INVOLVED)}
                            </Text>
                            <VehicleCard
                              onClick={() => {
                                navigate(
                                  getRoute(
                                    workspace?.id,
                                    paths.asset,
                                    incident?.vehicle?.id,
                                  ),
                                );
                              }}
                              vehicle={incident?.vehicle}
                            />
                          </div>
                        )
                      )}
                    </div>
                    {investigationQuestions.map((question) => (
                      <div key={question.id}>
                        {!question?.skipOnSubtype?.includes(
                          incident?.subtype,
                        ) && !question?.skipOnType?.includes(incident?.type) ? (
                          <>
                            <FormQuestion
                              question={question}
                              objectKey={question.convertToQuery}
                              value={incident[question.convertToQuery]}
                              update={updateIncident}
                              parentId={incident.id}
                              readOnly={
                                incident.status !== incidentStatus.initiated
                              }
                              incident={incident}
                              legacyInput={question.legacyInput}
                            />
                          </>
                        ) : null}
                      </div>
                    ))}
                    <br />
                    {incident.status === incidentStatus.initiated && (
                      <div className={styles.buttonContainer}>
                        <Text noMargin weight="semiBold" color="secondaryLight">
                          {submitDisabled &&
                            t(keys.action.SUBMIT_DISABLED_MESSAGE)}
                        </Text>
                        <Button
                          disabled={submitDisabled}
                          variant="success"
                          size="md"
                          value={t(keys.action.SUBMIT)}
                          onClick={() => {
                            if (isAdmin) {
                              openConfirmationModal({
                                title: t(keys.action.CONFIRM_CHANGES),
                                description: t(
                                  keys.action.PUBLISH_CONFIRMATION,
                                ),
                                variant: 'warning',
                                buttonText: t(keys.action.APPROVE),
                                onSubmit: () => {
                                  publishIncident({
                                    variables: {
                                      id: incident.id,
                                      status: incidentStatus.investigation,
                                    },
                                  });
                                },
                              });
                            } else {
                              publishIncident({
                                variables: {
                                  id: incident.id,
                                  status: incidentStatus.pending,
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Accordian>
              ) : (
                <div className={styles.spinnerContainer}>
                  <Spinner
                    animation="border"
                    variant="primary"
                    className={styles.spinner}
                  />
                </div>
              )}
              {incident.status === incidentStatus.pending && isAdmin && (
                <div className={styles.buttons}>
                  <Button
                    disabled={submitDisabled}
                    variant="success"
                    size="md"
                    value={t(keys.action.PUBLISH)}
                    onClick={() => {
                      openConfirmationModal({
                        title: t(keys.action.CONFIRM_CHANGES),
                        description: t(keys.action.PUBLISH_CONFIRMATION),
                        variant: 'warning',
                        buttonText: t(keys.action.APPROVE),
                        onSubmit: () => {
                          publishIncident({
                            variables: {
                              id: incident.id,
                              status: incidentStatus.investigation,
                            },
                          });
                          reviewIncident({
                            variables: {
                              incidentId: incident.id,
                              status: incidentStatus.investigation,
                            },
                          });
                        },
                      });
                    }}
                  />
                  <Button
                    disabled={submitDisabled}
                    variant="danger"
                    size="md"
                    value={t(keys.action.DEESCALATE)}
                    onClick={() => {
                      openConfirmationModal({
                        title: t(keys.action.DEESCALATE_VARIABLE, {
                          variable: t(keys.common.INCIDENT),
                        }),
                        description: t(keys.action.DEESCALATE_CONFIRMATION, {
                          variable: t(keys.common.INCIDENT),
                        }),
                        variant: 'warning',
                        buttonText: t(keys.action.DEESCALATE),
                        onSubmit: () => {
                          publishIncident({
                            variables: {
                              id: incident.id,
                              status: incidentStatus.closed,
                            },
                          });
                          reviewIncident({
                            variables: {
                              incidentId: incident.id,
                              status: incidentStatus.closed,
                            },
                          });
                        },
                      });
                    }}
                  />
                  <Button
                    disabled={submitDisabled}
                    variant="secondary"
                    size="md"
                    value={t(keys.action.DISMISS)}
                    onClick={() => {
                      openConfirmationModal({
                        title: t(keys.action.DISMISS_VARIABLE, {
                          variable: t(keys.common.INCIDENT),
                        }),
                        description: t(keys.action.DISMISS_CONFIRMATION, {
                          variable: t(keys.common.INCIDENT),
                        }),
                        variant: 'danger',
                        buttonText: t(keys.action.DISMISS),
                        onSubmit: () => {
                          publishIncident({
                            variables: {
                              id: incident.id,
                              status: incidentStatus.invalid,
                            },
                          });
                          reviewIncident({
                            variables: {
                              incidentId: incident.id,
                              status: incidentStatus.invalid,
                            },
                          });
                        },
                      });
                    }}
                  />
                </div>
              )}
              {approvedStatuses.includes(incident.status) &&
                !loadingIncident && (
                  <>
                    <Accordian
                      icon="image"
                      title={`${t(keys.common.IMAGES)}/${t(keys.common.FILES)}`}
                      message={combinedImages?.length || 0}
                      button={true}
                    >
                      <div className={styles.content}>
                        {incident?.status !== 'COMPLETE' && (
                          <div className={styles.newButton}>
                            <Button
                              value={t(keys.common.NEW)}
                              icon="add"
                              size="sm"
                              onClick={() => {
                                openModal({
                                  modalName: modals.fileUpload,
                                  variables: {
                                    simple: true,
                                    onSubmit: ({ url, name }) => {
                                      addIncidentImage({
                                        variables: {
                                          incidentId: incident.id,
                                          description: name,
                                          type: incident.status,
                                          imageUrl: url,
                                        },
                                      }).then(() => refetchImages());
                                    },
                                  },
                                });
                              }}
                            />
                          </div>
                        )}
                        {!!combinedImages?.length ? (
                          <div>
                            <div className={styles.outerImagesContainer}>
                              {allFiles?.map((f, index) => {
                                const extension = f.imageUrl
                                  .split('.')
                                  .pop()
                                  .toLowerCase();
                                const file = {
                                  ...f,
                                  fileType: extension,
                                  url: f.imageUrl,
                                  downloadAllowed: true,
                                };
                                return (
                                  <SimpleFileCard
                                    key={f.id}
                                    extension={extension}
                                    file={file}
                                    readOnly={
                                      f.__typename === 'ObservationImage' ||
                                      incident.status ===
                                        incidentStatus.complete
                                    }
                                    fileName={
                                      file.description ??
                                      `${extension.toUpperCase()}-observation-attachment-${index}`
                                    }
                                    onDelete={() => {
                                      openConfirmationModal({
                                        title: t(keys.action.DELETE_VARIABLE, {
                                          variable: t(keys.common.IMAGE),
                                        }),
                                        description: t(
                                          keys.action.CONFIRMATION_MESSAGE,
                                          {
                                            variable: t(
                                              keys.action.DELETE_VARIABLE,
                                              {
                                                variable: t(keys.common.IMAGE),
                                              },
                                            ),
                                          },
                                        ),
                                        variant: 'danger',
                                        onSubmit: () => {
                                          deleteIncidentImage({
                                            variables: { id: file.id },
                                          }).then(() => refetchImages());
                                        },
                                      });
                                    }}
                                  />
                                );
                              })}
                            </div>
                            <div className={styles.outerImagesContainer}>
                              {allImages?.map((image, index) => {
                                const extension = image.imageUrl
                                  .split('.')
                                  .pop()
                                  .toLowerCase();
                                const file = {
                                  ...image,
                                  fileType: extension,
                                  url: image.imageUrl,
                                };
                                return (
                                  <IncidentImageCard
                                    file={file}
                                    key={`${image}-${index}`}
                                    refetch={refetchImages}
                                    readOnly={
                                      image.__typename === 'ObservationImage' ||
                                      incident.status ===
                                        incidentStatus.complete
                                    }
                                    onDelete={() => {
                                      openConfirmationModal({
                                        title: t(keys.action.DELETE_VARIABLE, {
                                          variable: t(keys.common.IMAGE),
                                        }),
                                        description: t(
                                          keys.action.CONFIRMATION_MESSAGE,
                                          {
                                            variable: t(
                                              keys.action.DELETE_VARIABLE,
                                              {
                                                variable: t(keys.common.IMAGE),
                                              },
                                            ),
                                          },
                                        ),
                                        variant: 'danger',
                                        onSubmit: () => {
                                          deleteIncidentImage({
                                            variables: { id: file.id },
                                          }).then(() => refetchImages());
                                        },
                                      });
                                    }}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.emptyStateView}>
                            <EmptyStateView
                              size="sm"
                              title={t(keys.action.NOT_FOUND, {
                                variable: t(keys.common.IMAGES),
                              })}
                              text={
                                incident.status === 'COMPLETE'
                                  ? t(keys.incidents.INCIDENT_CLOSED)
                                  : t(keys.action.EMPTY_STATE_MESSAGE, {
                                      variable: t(keys.common.IMAGES),
                                    })
                              }
                              image={noImage}
                            ></EmptyStateView>
                          </div>
                        )}
                      </div>
                    </Accordian>
                    <Accordian
                      icon="content_paste"
                      title={t(keys.common.ASSESSMENTS)}
                      message={inspections?.length}
                    >
                      <div className={styles.content}>
                        {incident.status !== 'COMPLETE' && (
                          <div className={styles.newButton}>
                            <Button
                              value={t(keys.common.NEW)}
                              icon="add"
                              size="sm"
                              onClick={() => {
                                openModal({
                                  modalName: modals.createAssessment,
                                  variables: {
                                    types: [assessmentTypes.incident],
                                    title: t(
                                      keys.incidents.START_INCIDENT_ASSESSMENT,
                                    ),
                                    incidentId: incident.id,
                                    type: assessmentTypes.incident,
                                    participants: [user],
                                  },
                                });
                              }}
                            ></Button>
                          </div>
                        )}
                        {inspections?.length ? (
                          <div className={styles.inspectionList}>
                            <br />
                            <Table striped bordered hover>
                              <thead style={{ zIndex: 1 }}>
                                <tr>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.TITLE)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.CREATOR)}
                                    </Text>
                                  </th>
                                  <th className={styles.hideOnMobile}>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.LABELS)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.DATE_CREATED)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.STATUS)}
                                    </Text>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {inspections?.map((inspection) => (
                                  <tr
                                    key={inspection.id}
                                    onClick={() =>
                                      navigate(
                                        getRoute(
                                          workspace?.id,
                                          paths.assessment,
                                          inspection?.id,
                                        ),
                                      )
                                    }
                                  >
                                    <td>
                                      <Text size="sm" noMargin noSelect>
                                        {inspection?.template?.title}
                                      </Text>
                                    </td>
                                    <td>
                                      <div className={styles.creator}>
                                        <SimpleUserCard
                                          size="sm"
                                          user={inspection?.creator}
                                          hideAvatar
                                        />
                                      </div>
                                    </td>
                                    <td className={styles.hideOnMobile}>
                                      <div className={styles.labelContainer}>
                                        {inspection?.labels
                                          .slice(0, maxLabels)
                                          .map((label) => (
                                            <Label
                                              key={label.id}
                                              className={styles.label}
                                              name={label.name}
                                              color={label.color.toLowerCase()}
                                            />
                                          ))}
                                        {inspection.labels?.length >
                                          maxLabels && (
                                          <Text
                                            size="sm"
                                            weight="semiBold"
                                            noMargin
                                            color="accentPrimary"
                                          >
                                            {`+ ${
                                              inspection.labels.length -
                                              maxLabels
                                            }`}
                                          </Text>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <Text
                                        size="xs"
                                        noMargin
                                        noVerticalMargin
                                        noSelect
                                      >
                                        {getLocalTime(
                                          inspection?.dateCreated,
                                        ).format('MM/DD/YY')}
                                      </Text>
                                    </td>
                                    <td className={styles.labelContainer}>
                                      {inspection?.isDraft ? (
                                        <Label
                                          className={styles.label}
                                          color="yellow"
                                          name={t(keys.common.IN_PROGRESS)}
                                        />
                                      ) : (
                                        <Label
                                          className={styles.label}
                                          color="green"
                                          name={t(keys.common.COMPLETE)}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className={styles.emptyStateView}>
                            {!loadingInspections ? (
                              <EmptyStateView
                                size="sm"
                                title={t(keys.action.NOT_FOUND, {
                                  variable: t(keys.common.ASSESSMENTS),
                                })}
                                text={
                                  incident.status === 'COMPLETE'
                                    ? t(keys.incidents.INCIDENT_CLOSED)
                                    : t(keys.action.EMPTY_STATE_MESSAGE, {
                                        variable: t(keys.common.ASSESSMENTS),
                                      })
                                }
                                image={noInspections}
                              ></EmptyStateView>
                            ) : (
                              <div className={styles.spinnerContainer}>
                                <Spinner
                                  animation="border"
                                  variant="primary"
                                  className={styles.spinner}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Accordian>
                    <Accordian
                      icon="task_alt"
                      title={t(keys.common.ACTION_ITEMS)}
                      message={incidentActionItems?.actionItems?.length}
                      button={true}
                    >
                      <div className={styles.content}>
                        {incident.status !== 'COMPLETE' && (
                          <div className={styles.newButton}>
                            <Button
                              value={t(keys.common.NEW)}
                              icon="add"
                              size="sm"
                              onClick={() => {
                                addActionItem({
                                  variables: {
                                    title: incident.title,
                                    description: '',
                                    type: 'INCIDENT',
                                    incidentId: incident.id,
                                    workspaceId: workspace.id,
                                  },
                                }).then(
                                  ({
                                    data: {
                                      addActionItem: { id },
                                    },
                                  }) => {
                                    openActionItemModal(
                                      id,
                                      'INCIDENT',
                                      refetchActionItems,
                                    );
                                  },
                                );
                              }}
                            />
                          </div>
                        )}
                        {incidentActionItems?.actionItems?.length ? (
                          <div className={styles.actionItemsContainer}>
                            <br />
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.TITLE)}
                                    </Text>
                                  </th>
                                  <th>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.ASSIGNEE)}
                                    </Text>
                                  </th>
                                  <th
                                    className={classNames([
                                      styles.hideOnMobile,
                                      styles.labelsColumn,
                                    ])}
                                  >
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.LABELS)}
                                    </Text>
                                  </th>
                                  <th
                                    className={classNames([
                                      styles.hideOnMobile,
                                      styles.creatorColumn,
                                    ])}
                                  >
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.CREATOR)}
                                    </Text>
                                  </th>
                                  <th className={styles.statusColumn}>
                                    <Text
                                      size="sm"
                                      weight="semiBold"
                                      noMargin
                                      noSelect
                                    >
                                      {t(keys.common.STATUS)}
                                    </Text>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {incidentActionItems?.actionItems.map(
                                  (actionItem) => (
                                    <tr
                                      key={actionItem.id}
                                      onClick={() =>
                                        openActionItemModal(
                                          actionItem.id,
                                          refetchActionItems,
                                        )
                                      }
                                    >
                                      <td>
                                        <Text size="sm" noMargin>
                                          {actionItem.title}
                                        </Text>
                                      </td>
                                      <td>
                                        <Text size="sm" noMargin>
                                          {actionItem?.assignee
                                            ? `${actionItem?.assignee?.firstName} ${actionItem?.assignee?.lastName}`
                                            : t(keys.common.NONE)}
                                        </Text>
                                      </td>
                                      <td className={styles.hideOnMobile}>
                                        <div className={styles.labelContainer}>
                                          {actionItem?.labels
                                            .slice(0, maxLabels)
                                            .map((label) => (
                                              <Label
                                                key={label.id}
                                                className={styles.label}
                                                name={label.name}
                                                color={label.color.toLowerCase()}
                                              />
                                            ))}
                                          {actionItem.labels?.length >
                                            maxLabels && (
                                            <Text
                                              size="sm"
                                              weight="semiBold"
                                              noMargin
                                              color="accentPrimary"
                                            >{`+ ${
                                              actionItem.labels.length -
                                              maxLabels
                                            }`}</Text>
                                          )}
                                        </div>
                                      </td>

                                      <td className={styles.hideOnMobile}>
                                        <SimpleUserCard
                                          size="sm"
                                          user={actionItem.creator}
                                        />
                                      </td>

                                      <td>
                                        <Text
                                          size="sm"
                                          weight="semiBold"
                                          noMargin
                                        >
                                          {t(keys.common[actionItem.status])}
                                        </Text>
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className={styles.emptyStateView}>
                            {!loadingActionItems ? (
                              <EmptyStateView
                                size="sm"
                                title={t(keys.action.NOT_FOUND, {
                                  variable: t(keys.common.ACTION_ITEMS),
                                })}
                                text={
                                  incident.status === 'COMPLETE'
                                    ? t(keys.incidents.INCIDENT_CLOSED)
                                    : t(keys.action.EMPTY_STATE_MESSAGE, {
                                        variable: t(keys.common.ACTION_ITEMS),
                                      })
                                }
                                image={noInspections}
                              ></EmptyStateView>
                            ) : (
                              <Spinner
                                animation="border"
                                variant="primary"
                                className={styles.spinner}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Accordian>
                  </>
                )}
              {notesStatuses.includes(incident.status) && (
                <Accordian title={t(keys.common.NOTES)} icon="text_snippet">
                  <div className={styles.notesContainer}>
                    {incident.status !== 'COMPLETE' ? (
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            rows="10"
                            value={notes || ''}
                            onChange={(e) => setNotes(e.target.value)}
                          ></Form.Control>
                          <br />
                          <div className={styles.newButton}>
                            <Button
                              disabled={incident.notes === notes}
                              value={t(keys.action.SAVE)}
                              icon="save"
                              size="sm"
                              onClick={() => {
                                updateIncident({
                                  variables: {
                                    id: incident.id,
                                    notes: notes,
                                  },
                                });
                                refetchIncident();
                              }}
                            ></Button>
                          </div>
                        </Form.Group>
                      </Form>
                    ) : (
                      <Text>
                        {incident?.notes
                          ? incident?.notes
                          : t(keys.common.NO_NOTES_RECORDED)}
                      </Text>
                    )}
                  </div>
                </Accordian>
              )}
              {incident?.reviewers?.length > 0 && (
                <div>
                  <Text noMargin>
                    {`${t(keys.files.REVIEWED)} ${getLocalTime(
                      incident.reviewers[0].dateModified,
                    ).format('LLLL')} ${t(keys.common.BY)} ${
                      incident.reviewers[0].user.firstName
                    } ${incident.reviewers[0].user.lastName}`}
                  </Text>
                </div>
              )}
              {!!incident?.closer?.id && !!incident?.closerSignatureUrl && (
                <div>
                  <Text>
                    {t(keys.incidents.INCIDENT_CLOSED_ON, {
                      variable: `${getLocalTime(incident.closedAt).format(
                        'LLLL',
                      )} ${t(keys.common.BY)} ${incident.closer.firstName} ${
                        incident.closer.lastName
                      }`,
                    })}
                  </Text>
                  <img
                    className={styles.signature}
                    alt="signature"
                    src={incident.closerSignatureUrl}
                  />
                </div>
              )}

              <div className={styles.publishButton}>
                {incident.status === incidentStatus.closed &&
                  !incident?.observation &&
                  incident?.participants
                    .map(({ id }) => id)
                    .includes(user?.id) && (
                    <Button
                      className={styles.submitButton}
                      variant="primary"
                      size="md"
                      onClick={() => {
                        openModal({
                          modalName: modals.observation,
                          variables: {
                            participants: [...incident?.participants],
                            workspaceId,
                            templateId: observationTemplate?.id,
                            questions:
                              observationTemplate?.categories[0]?.questions ||
                              [],
                            dateTime: new moment().format('YYYY-MM-DD HH:mm'),
                            incidentId: incident?.id,
                          },
                        });
                      }}
                      icon={'content_paste_search'}
                      value={`${t(keys.action.CREATE_VARIABLE, {
                        variable: t(keys.common.OBSERVATION),
                      })}`}
                    />
                  )}
              </div>
              <div className={styles.publishButton}>
                {incident.status === incidentStatus.investigation && (
                  <Button
                    className={styles.submitButton}
                    variant="danger"
                    size="md"
                    onClick={() => {
                      signatureModalVar({
                        title: t(keys.action.REQUIRED, {
                          variable: t(keys.common.SIGNATURE),
                        }),
                        message: t(keys.incidents.CLOSE_INCIDENT_MESSAGE),
                        variant: 'warning',
                        buttonText: t(keys.incidents.CLOSE_INCIDENT),
                        show: true,
                        onSubmit: (url) => {
                          publishIncident({
                            variables: {
                              id: incident.id,
                              status: incidentStatus.complete,
                              closerSignatureUrl: url,
                            },
                          }).then(() => {
                            navigate(getRoute(workspace?.id, paths.incidents));

                            setTimeout(() => {
                              showToast({
                                title: incident.title,
                                message: t(keys.incidents.INCIDENT_CLOSED),
                                variant: toastVariant.info,
                                time: toastLength.md,
                              });
                            }, 1000);
                          });
                        },
                      });
                    }}
                    value={t(keys.incidents.CLOSE_INCIDENT)}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <IncidentSidebar
        onClose={() => setSidebarOpen(false)}
        inspections={inspections}
        incidentImages={combinedImages}
        refetch={() => refetchIncident()}
        className={!sidebarOpen && isMobile && styles.hide}
        incident={incident}
        title={incident.title}
      />
    </div>
  );
}
