/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import LeftSidebarPrototype from './chat/components/LeftSidebarPrototype';
import styles from './SidebarPrototypePage.module.scss';
import { animated, useSpring } from 'react-spring';
import Navbar from '../components/navbar/Navbar';
import { paths, userRoles } from '../constants/strings';
import { useReactiveVar } from '@apollo/client';
import { navigationExpandedVar } from '../graphql/cache/navbar';
import { useIsMobile } from '../hooks/misc';
import { useOnlineStatus } from '../hooks/offline-hooks/offline-misc';
import { closeNetworkModal, openNetworkModal } from '../graphql/cache/modal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNetworkModalStale } from '../utilities';
import { useCurrentUser } from '../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { keys } from '../utilities/translator/translation_keys';
import { useWorkspace } from '../providers/WorkspaceProvider';
import { useNavigate } from 'react-router-dom';

export default function SidebarPrototypePage({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useIsMobile();
  const { workspace, isWorkspaceAdmin } = useWorkspace();
  const navigationExpanded = useReactiveVar(navigationExpandedVar);
  const { user } = useCurrentUser();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onlineStatus = useOnlineStatus();

  useEffect(() => {
    if (!onlineStatus) {
      // User has gone offline - Show offline modal
      if (isNetworkModalStale()) {
        openNetworkModal({
          isOnline: false,
          onSubmit: closeNetworkModal,
        });
      }
    }
  }, [onlineStatus]);

  useEffect(() => {
    setCollapsed(false);
  }, [navigationExpanded]);

  const { marginLeft } = useSpring({
    config: { duration: 500 },
    width: collapsed ? '4.375rem' : '14rem',
    marginLeft: collapsed ? '4.375rem' : '13rem',
  });

  const bottomOptions = useMemo(() => {
    let originalBottomOpts = [
      {
        icon: 'settings',
        title: t(keys.settings.SETTINGS),
        path: paths.settings,
        param: 'settings',
      },
      {
        icon: 'logout',
        title: t(keys.utilities.SIGNOUT),
        onClick: async () => {
          navigate(paths.signout);
        },
        offline: true,
      },
    ];
    if (onlineStatus === false) {
      const offlineOptions = originalBottomOpts.filter((o) => o.offline);
      originalBottomOpts = offlineOptions;
    }

    return originalBottomOpts;
  }, [onlineStatus]);

  const { enableTimecards, enableExpenses, enableCustomToolboxTalks } =
    useFlags();

  const options = useMemo(() => {
    const originalOptions = [
      {
        icon: 'dashboard',
        title: t(keys.dashboard.DASHBOARD),
        path: `/workspace/${workspace?.id}/dashboard`,
        param: 'dashboard',
        offline: true,
      },
      {
        icon: 'task_alt',
        title: t(keys.common.ACTION_ITEMS),
        path: `/workspace/${workspace?.id}/actionitems`,
        param: 'actionitems',
      },
      {
        icon: 'inventory',
        title: t(keys.common.ASSESSMENTS),
        path: `/workspace/${workspace?.id}/assessments`,
        param: 'assessments',
        offline: true,
      },
      {
        icon: 'local_shipping',
        title: t(keys.common.ASSETS),
        path: `/workspace/${workspace?.id}/assets`,
        param: 'assets',
      },
      {
        icon: 'folder',
        title: t(keys.common.FILES),
        path: `/workspace/${workspace?.id}/${paths.workspaceFiles}`,
        param: 'files',
      },
      {
        icon: 'fmd_bad',
        title: user.company?.incidentName || t(keys.incidents.INCIDENTS),
        path: `/workspace/${workspace?.id}/incidents`,
        param: 'incidents',
      },
      {
        icon: 'content_paste_search',
        title: user.company?.observationName || t(keys.common.OBSERVATIONS),
        path: `/workspace/${workspace?.id}/${paths.observations}`,
        param: paths.observations,
      },
    ];

    const timecardOption = {
      icon: 'timer',
      title: t(keys.common.TIMECARDS),
      path: `/${paths.timecards}/personal`,
      param: 'timecards',
    };

    const expenseOption = {
      icon: 'paid',
      title: t(keys.common.EXPENSES),
      path: `/${paths.expenses}/personal`,
      param: 'expenses',
    };

    const announcementsOption = {
      icon: 'campaign',
      title: t(keys.common.ANNOUNCEMENTS),
      path: `/${paths.announcements}`,
      param: 'announcements',
    };

    const insightOption = {
      icon: 'insights',
      title: t(keys.common.INSIGHTS),
      path: paths.analytics,
      param: paths.analytics,
    };

    const taskOption = {
      icon: 'assignment_turned_in',
      title: t(keys.common.TASKS),
      path: `/${paths.tasks}`,
      param: 'tasks',
    };

    const toolboxOption = {
      icon: 'home_repair_service',
      title: t(keys.toolbox.TOOLBOX),
      path: '/toolbox',
      param: 'toolbox',
    };

    let newOptions = JSON.parse(JSON.stringify(originalOptions));

    if (user.role === userRoles.admin) {
      newOptions.push(insightOption);
      newOptions.push(taskOption);
    }

    if (enableCustomToolboxTalks && isWorkspaceAdmin) {
      newOptions.push(toolboxOption);
    }

    if (enableExpenses) {
      newOptions.push(expenseOption);
    }

    if (enableTimecards) {
      newOptions.push(timecardOption);
    }

    if (!isMobile && user.role === 'ADMIN') {
      newOptions.push(announcementsOption);
    }

    if (onlineStatus === false) {
      const offlineOptions = newOptions.filter((o) => o.offline);
      newOptions = offlineOptions;
    }

    return newOptions;
  }, [
    enableExpenses,
    enableTimecards,
    workspace?.id,
    onlineStatus,
    isMobile,
    i18n.language,
    user,
  ]);

  return (
    <div className={styles.background}>
      <LeftSidebarPrototype
        options={options}
        bottomOptions={bottomOptions}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <div>
        <animated.div style={{ marginLeft: isMobile ? 0 : marginLeft }}>
          <Navbar className={styles.navbar} left={marginLeft} />
          <div className={styles.page}>{children}</div>
        </animated.div>
      </div>
    </div>
  );
}
