import { paths } from '../constants/strings';
import { keys } from './translator/translation_keys';
import { t } from 'i18next';

export const actionMap = {
  ACTION_ITEM_ASSIGNED: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEMS),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_ASSIGNED_TITLE),
    description: t(keys.notifications.ACTION_ITEM_ASSIGNED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  ACTION_ITEM_REMINDER: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_DUE_DATE_TITLE),
    description: t(keys.notifications.ACTION_ITEM_DUE_DATE_DESCRIPTION, {
      variable:
        t(keys.notifications[actionItem.lastNotification]) ||
        t(keys.notifications.DUE),
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  ACTION_ITEM_COMMENT: ({ actionItemComment, creator }) => ({
    icon: 'task_alt',
    iconColor: 'primary',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_COMMENT_TITLE, {
      user: creator.firstName,
      actionItemTitle: actionItemComment.actionItem.title,
    }),
    description: actionItemComment.text,
    details: actionItemComment.text,
    url: `?actionitem=${actionItemComment.actionItem.id}`,
  }),
  ACTION_ITEM_COMPLETE: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'green',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_COMPLETE_TITLE),
    description: t(keys.notifications.ACTION_ITEM_COMPLETE_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  ACTION_ITEM_DUE_DATE: ({ actionItem, creator }) => ({
    icon: 'task_alt',
    iconColor: 'yellow',
    header: t(keys.common.ACTION_ITEM),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.ACTION_ITEM_DUE_DATE_TITLE),
    description: t(keys.notifications.ACTION_ITEM_DUE_DATE_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
      title: actionItem.title,
    }),
    url: `?actionitem=${actionItem.id}`,
  }),
  INSPECTION_COMPLETED: ({ inspection, creator, workspaceId }) => ({
    icon: 'inventory',
    iconColor: inspection.passed ? 'green' : 'red',
    header: t(keys.common.ASSESSMENTS),
    avatar: creator.avatarUrl,
    title: `${inspection.template.title} ${
      inspection.passed ? t(keys.common.PASSED) : t(keys.common.FAILED)
    }`,
    description: t(keys.notifications.ASSESSMENT_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName} ${
        inspection.participants.length > 1
          ? t(keys.notifications.ASSESSMENT_PARTICIPANTS)
          : ''
      }`,
    }),
    url: `/workspace/${workspaceId}/${paths.assessment}/${inspection.id}`,
  }),
  INCIDENT_REPORTED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'red',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_REPORTED_TITLE),
    description: t(keys.notifications.INCIDENT_REPORTED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
  }),
  INCIDENT_DEESCALATED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'red',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_DEESCALATED_TITLE),
    description: t(keys.notifications.INCIDENT_DEESCALATED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
  }),
  INCIDENT_DISMISSED: ({ creator, workspaceId, incident }) => ({
    icon: 'fmd_bad',
    iconColor: 'red',
    header: t(keys.common.INCIDENT),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.INCIDENT_DISMISSED_TITLE),
    description: t(keys.notifications.INCIDENT_DISMISSED_DESCRIPTION, {
      user: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/workspace/${workspaceId}/${paths.incident}/${incident?.id}`,
  }),
  EXPENSE_SUBMITTED: ({ creator, expense }) => ({
    icon: 'paid',
    iconColor: 'green',
    header: t(keys.common.EXPENSE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.EXPENSE_SUBMITTED_TITLE),
    description: t(keys.notifications.EXPENSE_SUBMITTED_DESCRIPTION, {
      variable: `${creator.firstName} ${creator.lastName}`,
    }),
    url: `/${paths.expense}/?id=${expense?.id}`,
  }),
  EXPENSE_DENIED: ({ creator, expense }) => ({
    icon: 'paid',
    iconColor: 'red',
    header: t(keys.common.EXPENSE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.EXPENSE_DENIED_TITLE),
    description: t(keys.notifications.EXPENSE_DENIED_DESCRIPTION),
    url: `/${paths.expense}/?id=${expense?.id}`,
  }),
  TIMECARD_DENIED: ({ creator, timecard }) => ({
    icon: 'timer',
    iconColor: 'red',
    header: t(keys.common.TIMECARD),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.TIMECARD_DENIED_TITLE),
    description: t(keys.notifications.TIMECARD_DENIED_DESCRIPTION),
    url: `/${paths.timecard}/?id=${timecard?.id}`,
  }),
  FILE_EXPIRY_REMINDER: ({ creator, file, workspaceId }) => ({
    icon: 'folder',
    iconColor: 'yellow',
    header: t(keys.common.FILE),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.FILE_TITLE, { variable: file.name }),
    description: t(keys.notifications.FILE_DESCRIPTION, {
      variable:
        t(keys.notifications[file.lastNotification]) || t(keys.dashboard.DUE),
    }),
    url: `/workspace/${workspaceId}/${paths.files}/${
      file.workspaceId ? 'workspace' : 'global'
    }?file=${file.id}&parent=${file.parentId}`,
  }),
  OBSERVATION_SUBMITTED: ({ creator, workspaceId, observation }) => ({
    icon: 'content_paste_search',
    iconColor: 'yellow',
    header: t(keys.common.OBSERVATION),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.OBSERVATION_SUBMITTED_TITLE),
    description: t(keys.notifications.OBSERVATION_SUBMITTED_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.observation}/${observation?.id}`,
  }),
  OBSERVATION_REVIEWED: ({ creator, workspaceId, observation }) => ({
    icon: 'content_paste_search',
    iconColor: 'yellow',
    header: t(keys.common.OBSERVATION),
    avatar: creator.avatarUrl,
    title: t(keys.notifications.OBSERVATION_REVIEWED_TITLE),
    description: t(keys.notifications.OBSERVATION_REVIEWED_DESCRIPTION),
    url: `/workspace/${workspaceId}/${paths.observation}/${observation?.id}`,
  }),
};
