import { keys } from '../utilities/translator/translation_keys';
import { customColors } from '../utilities';

export const stringKeys = {
  about: 'ABOUT',
  actionItems: 'ACTION_ITEMS',
  assets: 'ASSETS',
  book: 'BOOK',
  color: 'COLOR',
  company: 'COMPANY',
  completed: 'COMPLETED',
  completedBy: 'COMPLETED_BY',
  create: 'CREATE',
  createdBy: 'CREATED_BY',
  dashboard: 'DASHBOARD',
  date: 'DATE',
  email: 'EMAIL',
  emailPlaceholder: 'EMAIL_PLACEHOLDER',
  failed: 'FAILED',
  fleet: 'FLEET',
  forgotPassword: 'FORGOT_PASSWORD',
  forms: 'FORMS',
  HSE: 'HSE',
  humanResources: 'HUMAN_RESOURCES',
  inProgress: 'IN_PROGRESS',
  inspectionHistory: 'INSPECTION_HISTORY',
  inspections: 'INSPECTIONS',
  inspectionTemplates: 'INSPECTION_TEMPLATES',
  licensePlate: 'LICENSE_PLATE',
  location: 'LOCATION',
  make: 'MAKE',
  model: 'MODEL',
  odometer: 'ODOMETER',
  outcome: 'OUTCOME',
  passed: 'PASSED',
  password: 'PASSWORD',
  passwordPlaceholder: 'PASSWORD_PLACEHOLDER',
  privacy: 'PRIVACY',
  progress: 'PROGRESS',
  settings: 'SETTINGS',
  signIn: 'SIGN_IN',
  signInDesc: 'SIGN_IN_DESC',
  signInLeftDesc: 'SIGN_IN_LEFT_DESC',
  signInLeftHeader: 'SIGN_IN_LEFT_HEADER',
  signOut: 'SIGN_OUT',
  signUpPost: 'SIGN_UP_POST',
  signUpPre: 'SIGN_UP_PRE',
  status: 'STATUS',
  subtitle: 'SUBTITLE',
  subtype: 'SUBTYPE',
  templates: 'TEMPLATES',
  terms: 'TERMS',
  title: 'TITLE',
  type: 'TYPE',
  unitNumber: 'UNIT_NUMBER',
  units: 'UNITS',
  vehicle: 'VEHICLE',
  vehicleCreateP1Title: 'VEHICLE_CREATE_P1_TITLE',
  vehicleCreateP1Subtitle: 'VEHICLE_CREATE_P1_SUBTITLE',
  vehicleCreateP1Header: 'VEHICLE_CREATE_P1_HEADER',
  vehicleCreateP1Desc: 'VEHICLE_CREATE_P1_DESC',
  vehicleCreateP2Header: 'VEHICLE_CREATE_P2_HEADER',
  vehicleCreateP2Desc: 'VEHICLE_CREATE_P2_DESC',
  vehicleCreateP3Header: 'VEHICLE_CREATE_P3_HEADER',
  vehicleCreateP3Desc: 'VEHICLE_CREATE_P3_DESC',
  vehicleCreateP4Header: 'VEHICLE_CREATE_P4_HEADER',
  vehicleCreateP4Desc: 'VEHICLE_CREATE_P4_DESC',
  vehicleIdentificationNumber: 'VEHICLE_IDENTIFICATION_NUMBER',
  vehicles: 'VEHICLES',
  year: 'YEAR',
};

export const userRoles = {
  admin: 'ADMIN',
  user: 'USER',
};

export const userRoleStrings = {
  ADMIN: 'Admin',
  USER: 'Standard',
};

export const inspectionApprovalTypes = {
  approved: 'APPROVED',
  changesRequested: 'CHANGES_REQUESTED',
};

export const csvRequiredColumns = [
  'title',
  'description',
  'category',
  'input type',
  'required',
];

export const csvInputTypes = [
  'TEXT',
  'TEST',
  'IMAGE',
  'NUMBER',
  'SIGNATURE_SINGLE',
  'SIGNATURE_MULTI',
  'DATE',
  'USER',
  'SELECT_SINGLE',
  'SELECT_MULTI',
  'TABLE',
];

export const csvAdditionalDataInputTypes = [
  'TABLE',
  'SELECT_SINGLE',
  'SELECT_MULTI',
];

export const csvRequiredColumnData = [
  'title',
  'category',
  'input type',
  'required',
];

export const assessmentTypes = {
  fleet: {
    color: 'yellow',
    icon: 'local_shipping',
    title: keys.common.FLEET,
    key: 'VEHICLE',
    name: 'Fleet',
  },
  hse: {
    icon: 'health_and_safety',
    color: 'red',
    title: keys.common.HSE,
    key: 'HSE',
    name: 'HSE',
  },
  incident: {
    icon: 'fmd_bad',
    color: 'red',
    title: keys.common.INCIDENT,
    key: 'INCIDENT',
    name: 'Incident',
  },
  quality: {
    icon: 'auto_awesome',
    color: 'blue',
    title: keys.common.QUALITY,
    key: 'QUALITY',
    name: 'Quality',
  },
  commissioning: {
    icon: 'engineering',
    color: 'orange',
    title: keys.common.COMMISSIONING,
    key: 'COMMISSIONING',
    name: 'Commissioning',
  },
  meeting: {
    icon: 'calendar_month',
    color: 'green',
    title: keys.common.MEETING,
    key: 'MEETING',
    name: 'Meeting',
  },
  daily_report: {
    icon: 'assignment_ind_icon',
    color: 'darkBlue',
    title: keys.common.DAILY_REPORT,
    key: 'DAILY_REPORT',
    name: 'Daily Report',
  },
  observation: {
    icon: 'content_paste_search',
    color: 'red',
    title: keys.common.OBSERVATION,
    key: 'OBSERVATION',
    name: 'Observation',
  },
  orientation: {
    icon: 'school',
    color: 'pink',
    title: keys.common.ORIENTATION,
    key: 'ORIENTATION',
    name: 'Orientation',
  },
  maintenance: {
    icon: 'construction',
    color: 'purple',
    title: keys.common.MAINTENANCE,
    key: 'MAINTENANCE',
    name: 'Maintenance',
  },
  emergency: {
    icon: 'emergency',
    color: 'darkRed',
    title: keys.common.EMERGENCY,
    key: 'EMERGENCY',
    name: 'Emergency',
  },
};

export const getColor = (value) => {
  switch (value) {
    case 'maintenance':
      return customColors.PURPLE_LIGHT;
    case 'orientation':
      return customColors.PINK;
    case 'commissioning':
      return customColors.ORANGE;
    default:
      return assessmentTypes[value]?.color ?? customColors.YELLOW;
  }
};

export const sortAssessmentStrings = {
  dateCreated: keys.common.DATE,
  isDraft: keys.common.STATUS,
  title: keys.common.TITLE,
  creatorId: keys.common.CREATOR,
};
export const sortExpenseStrings = {
  dateCreated: 'Date Created',
  status: 'Status',
  dateModified: 'Date Modified',
  dateOfExpense: 'Expense Date',
};

export const sortIncidentStrings = {
  dateCreated: keys.common.DATE,
  status: keys.common.STATUS,
  creatorId: keys.common.CREATOR,
  title: keys.common.TITLE,
  type: keys.common.TYPE,
  subtype: keys.common.SUBTYPE,
};
export const sortTemplateStrings = {
  dateCreated: keys.common.DATE,
  isDraft: keys.common.STATUS,
  title: keys.common.TITLE,
  userId: keys.common.CREATOR,
};

export const sortVehicleStrings = {
  make: keys.assets.MAKE,
  model: keys.assets.MODEL,
  unitNumber: keys.assets.UNIT_NUMBER,
  dateCreated: keys.common.DATE_ADDED,
  year: keys.assets.YEAR,
};
export const sortActionItemStrings = {
  priority: keys.utilities.PRIORITY,
  title: keys.utilities.TITLE,
  creatorId: keys.utilities.CREATOR,
  assigneeId: keys.utilities.ASSIGNEE,
  status: keys.utilities.STATUS,
  dateCreated: keys.common.DATE,
};

export const sortTimecardStrings = {
  startTime: 'Start Time',
  endTime: 'End Time',
  billableHours: 'Hours',
  status: 'Status',
  poNumber: 'PO Number',
  dateModified: 'Date Modified',
};

export const sortFileStrings = {
  dateCreated: keys.common.DATE_ADDED,
  dateModified: keys.common.DATE_MODIFIED,
  name: keys.common.TITLE,
  expires: keys.action.EXPIRES,
};

export const sortTaskStrings = {
  dateCreated: keys.common.DATE_ADDED,
  title: keys.common.TITLE,
};

export const paths = {
  actionItems: 'actionitems',
  analytics: 'analytics',
  announcements: 'announcements',
  dashboard: 'dashboard',
  assessments: 'assessments',
  assets: 'assets',
  settings: '/settings',
  asset: 'asset',
  files: 'files',
  assessment: 'assessment',
  templates: 'templates',
  logout: 'logout',
  login: 'login',
  incidents: 'incidents',
  incident: 'incident',
  timecard: 'timecard',
  timecards: 'timecards',
  incidenttemplate: 'incidenttemplate',
  workspaceSettings: 'workspacesettings',
  workspaceFiles: 'files/workspace',
  expenses: 'expenses',
  expense: 'expense',
  signout: 'signout',
  observations: 'observations',
  observation: 'observation',
  user: 'user',
  tasks: 'tasks',
  toolbox: 'toolbox',
};

export const templateFilePaths = {
  ExcelTemplate:
    'https://app.opasmobile.com/assets/templates/files/OPASTemplateCreatorExcel.xlsx',
  ImportInstructions:
    'https://app.opasmobile.com/assets/templates/files/OPASTemplateImportInstructions.pdf',
};

export function getRoute(workspaceId, path, extension, params) {
  return extension
    ? `/workspace/${workspaceId}/${path}/${extension}${params ? params : ''}`
    : `/workspace/${workspaceId}/${path}${params ? params : ''}`;
}
export function capitalizeTitle(title) {
  return title?.charAt(0).toUpperCase() + title?.slice(1).toLowerCase();
}

export const inputTypes = {
  image: 'IMAGE',
  number: 'NUMBER',
  test: 'TEST',
  text: 'TEXT',
  table: 'TABLE',
  user: 'USER',
  select: 'SELECT_SINGLE',
  multiSelect: 'SELECT_MULTI',
  signature: 'SIGNATURE_SINGLE',
  multiSignature: 'SIGNATURE_MULTI',
  date: 'DATE',
  textMulti: 'TEXT_MULTI',
  matrix: 'MATRIX',
  time: 'TIME',
  dateTime: 'DATE_TIME',
};

export const incidentStatus = {
  initiated: 'INITIATED',
  pending: 'PENDING',
  investigation: 'INVESTIGATION',
  complete: 'COMPLETE',
  closed: 'CLOSED',
  invalid: 'INVALID',
};
export const searchParamKeys = {
  actionItem: 'actionitem',
  type: 'type',
  board: 'board',
  file: 'file',
  parent: 'parent',
  hidden: 'hidden',
  tab: 'tab',
  template: 'template',
  asset: 'asset',
  modalName: 'modalName',
  observation: 'observation',
};

export const offlineTextsStrings = {
  featureDisabled: ' Not Available Offline',
};

export const announcementIcons = {
  READ: { icon: 'check_circle', color: 'green' },
  SNOOZED: { icon: 'snooze', color: 'yellow' },
  UNREAD: { icon: 'new_releases', color: 'red' },
};

export const taskIntervalStringLookup = {
  ONE_TIME: keys.tasks.ONE_TIME,
  DAILY: keys.tasks.DAILY,
  MONTHLY: keys.tasks.MONTHLY,
  WEEKLY: keys.tasks.WEEKLY,
};

export const companyScopedKeys = [
  'timecards',
  'expenses',
  'announcements',
  'insights',
  'analytics',
  'settings',
  'tasks',
  'toolbox',
];

export const companyWideTable = ['incidents', 'observations'];
