import TemplatesList from '../fleet/TemplatesList';
import styles from './ObservationTemplates.module.scss';
import { Text } from '../../components/typography';
import { useWorkspace } from '../../providers/WorkspaceProvider';
import { useState } from 'react';
import { Searchbar } from '../../components';
import { useMemo, useRef, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { closeModalComponents } from '../../utilities';
import { useTranslation } from 'react-i18next';
import { keys } from '../../utilities/translator/translation_keys';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import defaultLogo from '../../assets/workspace_image.png';

const updateTemplateMutation = loader(
  './ObservationTemplates.updateWorkspace.graphql',
);
const observationTemplatesQuery = loader(
  './ObservationTemplates.fetch.graphql',
);

export default function ObservationTemplates() {
  const { availableWorkspaces, refetchWorkspace } = useWorkspace();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [search, setSearch] = useState('');
  const [updateWorkspaceTemplate] = useMutation(updateTemplateMutation);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const ref = useRef();
  const { t } = useTranslation();
  const { data: { inspectionTemplates = [] } = {} } = useQuery(
    observationTemplatesQuery,
    {
      variables: {
        options: {
          filters: [
            {
              field: 'templateType',
              operator: 'eq',
              value: 'OBSERVATION',
            },
            { field: 'isArchived', operator: 'eq', value: ['false'] },
            { field: 'isDraft', operator: 'eq', value: ['false'] },
          ],
        },
      },
    },
  );
  const templateOptions = useMemo(
    () =>
      inspectionTemplates
        ?.filter((i) => i.templateType === 'OBSERVATION')
        .map((w) => ({ value: w?.id, label: w?.title })),
    [inspectionTemplates],
  );

  useEffect(() => {
    closeModalComponents(ref, setSelectedWorkspace);
    if (!selectedWorkspace) {
      setSelectedTemplate(null);
    }
  }, [ref, selectedWorkspace, setSelectedWorkspace]);

  const filteredWorkspaces = useMemo(() => {
    return search?.length
      ? availableWorkspaces.filter((opt) =>
          opt.title.toLowerCase().includes(search.toLowerCase()),
        )
      : availableWorkspaces;
  }, [availableWorkspaces, search]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.search}>
          <Searchbar
            value={search}
            onChange={(newValue) => setSearch(newValue)}
            title={t(keys.common.WORKSPACE)}
          />
        </div>
        <div className={styles.cards} ref={ref}>
          {!filteredWorkspaces?.length ? (
            <Text>
              {t(keys.action.NOT_FOUND, { variable: t(keys.common.WORKSPACE) })}
            </Text>
          ) : (
            filteredWorkspaces?.map(
              ({ id, title, observationTemplate, logoUrl }) => {
                return (
                  <div
                    key={id}
                    className={
                      selectedWorkspace === id ? styles.selected : styles.card
                    }
                    onClick={() => {
                      setSelectedTemplate(
                        observationTemplate
                          ? {
                              value: observationTemplate?.id,
                              label: observationTemplate?.title,
                            }
                          : null,
                      );
                      setSelectedWorkspace(id);
                    }}
                  >
                    <div className={styles.workspaceContainer}>
                      <img
                        src={logoUrl || defaultLogo}
                        alt="workspace"
                        className={
                          selectedWorkspace === id
                            ? styles.selectedLogo
                            : styles.logoUrl
                        }
                      />
                      <div className={styles.workspaceRight}>
                        <Text truncate size="sm" weight="semiBold" noMargin>
                          {title}
                        </Text>
                        {selectedWorkspace !== id ? (
                          <Text
                            truncate
                            noMargin
                            color={
                              observationTemplate ? 'accentPrimary' : 'primary'
                            }
                            weight="semiBold"
                            hover
                            className={styles.templateTitle}
                          >
                            {observationTemplate?.title || t(keys.common.NONE)}
                          </Text>
                        ) : (
                          <div className={styles.selectedWorkspace}>
                            <Select
                              className={styles.dropdown}
                              options={templateOptions}
                              value={selectedTemplate}
                              isClearable
                              onChange={(template) => {
                                updateWorkspaceTemplate({
                                  variables: {
                                    id,
                                    observationTemplateId:
                                      template?.value || null,
                                  },
                                }).then(() => {
                                  refetchWorkspace();
                                  setSelectedTemplate(template);
                                });
                              }}
                            />
                            <Text
                              color="secondary"
                              size="xs"
                              textAlign="center"
                            >
                              {t(keys.observations.SELECT_TEMPLATE)}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              },
            )
          )}
        </div>
      </div>
      <div className={styles.right}>
        <TemplatesList
          selected={selectedTemplate?.value || null}
          showType={false}
        />
      </div>
    </div>
  );
}
