import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

export const useTableURLParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tableFiltersToURL = (filters) => {
    const searchParams = new URLSearchParams(location.search);

    for (const key of Array.from(searchParams.keys())) {
      if (!nonFilterParams.includes(key)) {
        searchParams.delete(key);
      }
    }

    if (!!filters) {
      filters?.forEach(({ id, value }) => {
        if (!!value) {
          if (Array.isArray(value)) {
            searchParams.set(id, `${value[0]}__${value[1]}`);
          } else {
            searchParams.set(id, value);
          }
        }
      });
      subFilterParams.forEach((key) => {
        if (!filters.find((filter) => filter.id === key)) {
          searchParams.delete(key);
        }
      });

      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  return { tableFiltersToURL };
};

export const formatFilters = (originalField, originalValue, userId) => {
  let operator = 'eq';
  let value = originalValue;
  let field = originalField;

  switch (originalField) {
    case 'allWorkspaces':
      field = 'assignee';
      operator = 'eq';
      value = userId;
      break;
    case 'description':
    case 'title':
      operator = 'iLike';
      value = `%${value}%`;
      break;
    case 'reviewer':
    case 'creator':
    case 'workspace':
    case 'closer':
    case 'assignee':
      field = `${field}Id`;
      break;
    case 'Passed':
      field = 'passed';
      break;
    case 'dateTime':
    case 'dateCreated':
    case 'dateModified':
    case 'dueDate':
      const [min, max] = originalValue;
      const dateMin =
        min && moment(min).isValid()
          ? moment(min).format('YYYY-MM-DD 00:00:00')
          : null;
      const dateMax =
        max && moment(max).isValid()
          ? moment(max).format('YYYY-MM-DD 23:59:59')
          : null;
      if (dateMin && !dateMax) {
        operator = 'gte';
        value = [dateMin];
      } else if (!dateMin && dateMax) {
        operator = 'lte';
        value = [dateMax];
      } else if (dateMin && dateMax) {
        operator = 'between';
        value = [dateMin, dateMax];
      } else {
        return null;
      }
      break;
    default:
      break;
  }

  return { field, operator, value };
};
export const formatSubFilter = (originalField, originalValue) => {
  switch (originalField) {
    case 'template.title':
      return {
        association: originalField.split('.')[0],
        field: originalField.split('.')[1],
        operator: 'iLike',
        value: [`%${originalValue}%`],
      };
    default:
      return {};
  }
};

export const nonFilterParams = [
  'pageIndex',
  'pageSize',
  'allWorkspaces',
  'actionitem',
  'sort',
  'desc',
  'open',
  'tab',
  'template.title',
  'modalName',
];
export const subFilterParams = ['template.title'];
